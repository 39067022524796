export const monthsWithWeeks = (t) => {
  let data = [
    `JAN (${t("property_page.Week")}-1)`,
    `JAN (${t("property_page.Week")}-2)`,
    `JAN (${t("property_page.Week")}-3)`,
    `JAN (${t("property_page.Week")}-4)`,
    `FEB (${t("property_page.Week")}-5)`,
    `FEB (${t("property_page.Week")}-6)`,
    `FEB (${t("property_page.Week")}-7)`,
    `FEB (${t("property_page.Week")}-8)`,
    `MAR (${t("property_page.Week")}-9)`,
    `MAR (${t("property_page.Week")}-10)`,
    `MAR (${t("property_page.Week")}-11)`,
    `MAR (${t("property_page.Week")}-12)`,
    `APR (${t("property_page.Week")}-13)`,
    `APR (${t("property_page.Week")}-14)`,
    `APR (${t("property_page.Week")}-15)`,
    `APR (${t("property_page.Week")}-16)`,
    `MAY (${t("property_page.Week")}-17)`,
    `MAY (${t("property_page.Week")}-18)`,
    `MAY (${t("property_page.Week")}-19)`,
    `MAY (${t("property_page.Week")}-20)`,
    `JUN (${t("property_page.Week")}-21)`,
    `JUN (${t("property_page.Week")}-22)`,
    `JUN (${t("property_page.Week")}-23)`,
    `JUN (${t("property_page.Week")}-24)`,
    `JUL (${t("property_page.Week")}-25)`,
    `JUL (${t("property_page.Week")}-26)`,
    `JUL (${t("property_page.Week")}-27)`,
    `JUL (${t("property_page.Week")}-28)`,
    `AUG (${t("property_page.Week")}-29)`,
    `AUG (${t("property_page.Week")}-30)`,
    `AUG (${t("property_page.Week")}-31)`,
    `AUG (${t("property_page.Week")}-32)`,
    `SEP (${t("property_page.Week")}-33)`,
    `SEP (${t("property_page.Week")}-34)`,
    `SEP (${t("property_page.Week")}-35)`,
    `SEP (${t("property_page.Week")}-36)`,
    `OCT (${t("property_page.Week")}-37)`,
    `OCT (${t("property_page.Week")}-38)`,
    `OCT (${t("property_page.Week")}-39)`,
    `OCT (${t("property_page.Week")}-40)`,
    `NOV (${t("property_page.Week")}-41)`,
    `NOV (${t("property_page.Week")}-42)`,
    `NOV (${t("property_page.Week")}-43)`,
    `NOV (${t("property_page.Week")}-44)`,
    `DEC (${t("property_page.Week")}-45)`,
    `DEC (${t("property_page.Week")}-46)`,
    `DEC (${t("property_page.Week")}-47)`,
    `DEC (${t("property_page.Week")}-48)`,
  ];
  return data;
};
