import { Button, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const PropertyWizard = ({
  setStep,
  setProperties,
  properties,
  setStopStep,
}) => {
  const { t } = useTranslation();

  const handleProperties = (e) => {
    //debugger;
    setProperties(Array.from({ length: e.target.value }, () => ({})));
  };

  const handleChange = (e, index) => {
    const user = JSON.parse(localStorage.getItem("user"));

    let updatedData = properties?.map((el, ind) => {
      if (ind === index) {
        return {
          ...el,
          [e.target.name]: e.target.value?.toUpperCase(),
          tenantId: user?._id,
        };
      } else {
        return el;
      }
    });
    setProperties(updatedData);
  };

  const handleAddBuilding = (propIndex) => {
    let updatedData = properties?.map((el, ind) => {
      if (ind === propIndex) {
        return {
          ...el,
          buildings: (el?.buildings || 0) + 1,
        };
      } else {
        return el;
      }
    });
    setProperties(updatedData);
  };
  const handleRemoveBuilding = (propIndex) => {
    let updatedData = properties?.map((el, ind) => {
      if (ind === propIndex) {
        return {
          ...el,
          buildings: (el?.buildings || 1) - 1,
        };
      } else {
        return el;
      }
    });
    setProperties(updatedData);
  };

  const handleSubmit = () => {
    let updatedData = properties?.map((el) => {
      if (el?.buildings) {
        const buildingsObj = [];
        for (let i = 1; i <= el?.buildings; i++) {
          buildingsObj.push({
            building_code: `${el?.property_code}0${i}`,
            property_code: el?.property_code,
          });
        }
        return {
          ...el,
          buildingsArray: buildingsObj,
        };
      } else {
        return el;
      }
    });
    setProperties(updatedData);
    //debugger;
    // setStep(6);
    setStopStep("BuildingPage");
  };

  const handleRemoveProp = (propIndex, pro) => {
    //debugger;

    let newData = [];
    for (let i = 0; i < properties.length; i++) {
      if (properties[i]?.property_code !== pro?.property_code) {
        newData.push(properties[i]);
      }
    }
    console.log(newData);
    setProperties(newData);
    //debugger;
    // let newData = properties.filter((elem, index) => {
    //   return elem?.property_code !== pro?.property_code;
    // });

    // setProperties(newData);
    // setProperties([
    //   { property_code: "dfd" },
    //   { property_code: "dfd565" },
    //   { property_code: "dfd898" },
    // ]);
  };
  const handleBack = () => {
    setStopStep(null);
    setStep(3);
  };
  return (
    <>
      <span
        class="material-symbols-outlined step_arrow_back"
        onClick={handleBack}
      >
        arrow_back
      </span>
      <div className="property_wizard_main">
        <p className="property_wizard_head">
          {t("common.pages.How many properties do you want to create?")}
        </p>
        <div className="wizard_slect_main">
          <Form.Select
            aria-label="Default select example"
            className="property_wizard_slect"
            onChange={handleProperties}
            value={properties?.length || ""}
          >
            <option disabled value="">
              {t("common.pages.Select no")}
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </Form.Select>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="all_property_card_main">
            {properties?.map((elem, index) => {
              return (
                <div className="property_card_main" key={index}>
                  <div className="prop_delete_icon_main">
                    <div
                      className="delete_icon_main"
                      onClick={() => handleRemoveProp(index, elem)}
                    >
                      <span class="material-symbols-outlined">delete</span>
                    </div>
                  </div>
                  <br /> <br />
                  <div className="property_fields_main">
                    {/*  */}
                    <Form.Group className="setting_name property_name_field">
                      <div className="setting_name_div step4_name_label">
                        {/* {t("common.pages.name")} */}
                        Nummer
                      </div>
                      <Form.Control
                        name="property_code"
                        type="number"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                        className="setting_name_field"
                      />
                    </Form.Group>
                    <Form.Group className="setting_name property_name_field">
                      <div className="setting_name_div step4_name_label">
                        {t("common.pages.legal_name")}
                        {/* Legal Name */}
                      </div>
                      <Form.Control
                        name="legal_name"
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                        className="setting_name_field"
                        value={elem?.legal_name}
                      />
                    </Form.Group>
                    <Form.Group className="setting_name property_name_field">
                      <div className="setting_name_div step4_name_label">
                        {t("common.pages.name")}
                        {/* Name */}
                      </div>
                      <Form.Control
                        name="name"
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                        className="setting_name_field"
                        value={elem?.name}
                      />
                    </Form.Group>
                    <div className="property_building_main">
                      <p className="building_how_build_head">
                        {t(
                          "common.pages.How many buildings are there on the property?"
                        )}
                      </p>
                      <div className="buildnum_main">
                        <Button
                          className="delete_icon_main building_remove_icon_main"
                          disabled={!elem?.buildings}
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => handleRemoveBuilding(index)}
                          >
                            remove
                          </span>
                        </Button>

                        <div className="property_building_num">
                          {elem?.buildings || (
                            <span class="material-symbols-outlined">
                              remove
                            </span>
                          )}
                        </div>
                        <Button
                          className="delete_icon_main building_remove_icon_main"
                          disabled={elem?.buildings === 5}
                        >
                          <span
                            class="material-symbols-outlined"
                            onClick={() => handleAddBuilding(index)}
                          >
                            add
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="step1_submit_btn_main step_4continue">
            <Button
              className="step1_started_btn"
              main
              type="submit"
              disabled={properties?.length > 0 ? false : true}
            >
              {t("common.pages.Continue")}
            </Button>
          </div>
        </Form>
        {/*  */}
      </div>
    </>
  );
};

export default PropertyWizard;
