import React, { useState } from "react";
import Settings from "./settings/Settings";
import BillingInfo from "./billinginfo/BillingInfo";
import Myprofile from "./myprofile/myprofile";
import { useEffect } from "react";
import { usePropertyContextCheck } from "context/SidebarContext/PropertyContextCheck";
import api from "api";
import "./userAccounts.css";

const UserAccountsPage = ({
  myprofile,
  settings,
  Billinginfo,
  setCurrentTab,
  currentTab,
  setUser,
  ...props
}) => {
  const { selectedUser, setSelectedUser } = usePropertyContextCheck();
  const [users, setUsers] = useState([]);
  const [dupUsers, setDupUsers] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [fetchUsers, setFetchUsers] = useState(false)

  const getAllUser = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      let allprofileUser = await api.get(
        `/users/adminId/${user?._id}?admin=${true}`
      );
      console.log(allprofileUser?.data);
      setUsers(allprofileUser?.data);
      setDupUsers(allprofileUser?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUser();
  }, [fetchUsers]);

  return (
    <div>
      {currentTab === "myprofile" ? (
        <Myprofile
          {...props}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          users={users}
          setUsers={setUsers}
          setCurrentTab={setCurrentTab}
          setUser={setUser}
          dupUsers={dupUsers}
          admin={admin}
          currentTab={currentTab}
          setFetchUsers={setFetchUsers}
          fetchUsers={fetchUsers}
        />
      ) : currentTab === "settings" ? (
        <Settings
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          users={users}
          setUsers={setUsers}
          setAdmin={setAdmin}
        />
      ) : currentTab === "Billinginfo" ? (
        <BillingInfo />
      ) : null}
    </div>
  );
};

export default UserAccountsPage;
