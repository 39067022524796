import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

function getToken() {
  if (localStorage.getItem("token")) {
    const token = JSON.parse(localStorage.getItem("token") || "");
    return token;
  }
  return "";
}

api.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    return error;
  }
);

api.interceptors.response.use(
  (response) => {
    // if (response.data?.message) {
    //   toast.success(response.data?.message);
    // }
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      if (window.location.href.includes("onboarding")) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user?.email) {
          let result = await api.post("/auth/generate-token", {
            email: user.email,
          });
          if (result.status == 201) {
            localStorage.setItem("token", JSON.stringify(result.data));
          }
        }
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location = "/sign-in";
      }
    } else if (
      error.response?.data?.message &&
      !error.response?.data?.maxUser
    ) {
      toast.error(error.response?.data?.message);
    } else if (error.response?.data) {
      toast.error(error.response?.data);
    } else if (error?.message) {
      toast.error(error?.message);
    } else if (error.response?.data?.error) {
      toast.error(error.response.data.error);
    }

    return error;
  }
);

export default api;
