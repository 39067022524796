import { FilterReportesPerType, GetFilters } from "lib/PlanningLib";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "components/common/Loader";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";
import MultiRangeSlider from "components/common/MultiRangeSlider";
import { Dropdown, Table } from "@themesberg/react-bootstrap";
import "../ActivitesYear/activitesYear.css";
import Filter from "components/common/Filter";

import api from "api";
import leaf_icon from "../../../../../../assets/img/report/icon_leaf.png";
import money_icon from "../../../../../../assets/img/report/icon_money.png";
import risk_icon from "../../../../../../assets/img/report/icon_risk major.png";
import project_icon from "../../../../../../assets/img/report/icon_project.png";
import search_icon from "../../../../../../assets/img/report/icon_search.png";
import constructionImg from "../../../../../../assets/img/construction.png";
import { FaCaretDown } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { SidePanelRoot, SidePanelService } from "components/common/SidePanel";
import filesSidePanel from "../ActivitesYear/filesSidePanel";
import { toast } from "react-toastify";
import ActivitesYearSidePanel from "../ActivitesYear/activitesYearSidePanel";
import DeleteModal from "../ActivitesYear/DeleteModal";
import DetailModal from "../ActivitesYear/detailModal";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintModal from "../ActivitesYear/PrintModal";
import { usePlanningContextCheck } from "context/SidebarContext/PlanningContextCheck";
import {
  depOptions,
  getAllMaintenanceDiagramData,
  getMaintenanceDepositionData,
  getMaintenanceReport,
  getMaintenanceSettings,
  options,
  sortContent,
  contentTexts,
  contentTextsVariables,
  uniquePropertyAndBuildings,
} from "utils/MaintenanceReport";
import PrintData from "../ActivitesYear/PrintData";
import { GetAllProperties } from "lib/PropertiesLib";
import Switch from "../../../../../common/Switch";
import jsPDF from "jspdf";
import PTSans from "../../../../../../utils/font";
import PTSansBold from "../../../../../../utils/font-bold";
import html2canvas from "html2canvas";
import { Bar, Line } from "react-chartjs-2";

const ActivitesType = ({
  printItem,
  createReport,
  handleChangeAction,
  currReprtTab,
}) => {
  // Filter State
  const { value } = GetAllProperties(undefined, undefined, true);
  const [showFilters, setShowFilters] = useState(false);
  const printRef = useRef();
  const [filterValues, setFilterValues] = useState({});
  const { value: allFilters } = GetFilters();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showMenuCol, setShowMenuCol] = useState(true);
  const [printModal, setPrintModal] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [calculation, setCalculation] = useState(true);
  const [printLoader, setPrintLoader] = useState(false);

  const chartRef = useRef(null);
  const lineChartRef = useRef(null);
  const htmlContentRef = useRef(null);

  // Filter Code
  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  };

  // activites year State
  const [maintainancePlan, setMaintainancePlan] = useState([]);
  const [dupMaintainancePlan, setDupMaintainancePlan] = useState([]);

  const [breakIndexs, setBreakIndexs] = useState([]);
  const [printData, setPrintData] = useState(null);
  const [dupPrintData, setDupPrintData] = useState(null);

  // Side Panel State
  const [initalVal, setInitalVal] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [formData, setFormData] = useState(null);

  // Detail Modal State
  const [detailModal, setDetailModal] = useState(false);

  const [selectedPoints, setSelectedPoints] = useState([]);
  const [depositionData, setDepositionData] = useState(null);
  const [uniquePropsAndBuilds, setUniquePropsAndBuilds] = useState(null);
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [maintenanceSettings, setMaintenanceSettings] = useState(null);
  const [pageNumbering, setPageNumbering] = useState({});
  const [maintananceDiagramData, setMaintananceDiagramData] = useState({
    labels: [],
    datasets: [],
  });
  const { actvsPerYearBreakIndexs, actvsPerYearPrintData } =
    usePlanningContextCheck();

  const { setActvsPerTypePrintData, setActvsPerTypeBreakIndexs } =
    usePlanningContextCheck();

  const { actvsPerTypeBreakIndexs, actvsPerTypePrintData } =
    usePlanningContextCheck();

  // Delete Modal State
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);

  const handleDeleteFilter = (valueToDelete) => {
    Object.keys(filterValues).forEach((key) => {
      if (Array.isArray(filterValues[key])) {
        const index = filterValues[key].indexOf(valueToDelete);
        if (index !== -1) {
          setFilterValues((prevObject) => ({
            ...prevObject,
            [key]: prevObject[key].filter((item, i) => i !== index),
          }));
        }
      }
    });
  };

  const handleFindClick = async () => {
    // perform find logic using filterValues

    let filterObj = {};
    for (const key in filterValues) {
      if (filterValues[key]?.length > 0) {
        filterObj[key] = filterValues[key];
      }
    }
    const res = await FilterReportesPerType({
      body: JSON.stringify({
        filters: filterObj,
      }),
    });
    const newData = await res.json();
    setMaintainancePlan(newData);
  };

  const filterAnimation = {
    maxHeight: showFilters ? "500px" : "0",
    opacity: showFilters ? "1" : "0",
    visibility: showFilters ? "visible" : "hidden",
    transition: "max-height 0.5s ease-out, opacity 0.5s ease-out",
  };

  const handleFilterChange = (name, value) => {
    setFilterValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  function splitArray(array, chunkSize, elem) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push({ ...elem, documents: array.slice(i, i + chunkSize) });
    }
    return result;
  }

  const getAllMaintenancePlan = async (spinner) => {
    if (spinner === false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      let allMaintenancePlan = await api.post(
        "/planning_component/maintainance/activitesPertype"
      );
      let allData = JSON.parse(JSON.stringify(allMaintenancePlan));
      let indexs = [];
      let updatedData = [];
      let a = 0;

      for (let i = 0; i < allData?.data?.length; i++) {
        if (a == 9) {
          updatedData.push(allData?.data[i]);
          indexs.push(i);
          a = allData?.data[i]?.documents?.length;
        } else if (a + allData?.data[i]?.documents?.length > 9) {
          let remaining = 9 - a;
          let remElem = allData?.data[i]?.documents.splice(0, remaining);
          let splitArrays;
          if (allData?.data[i]?.documents?.length > 9) {
            splitArrays = splitArray(
              allData?.data[i]?.documents,
              9,
              allData?.data[i]
            );
          } else {
            splitArrays = [allData?.data[i]];
          }
          updatedData.push(
            {
              ...allData?.data[i],
              documents: remElem,
            },
            // allData?.data[i]
            ...splitArrays
          );

          indexs.push(updatedData?.length - 1);
          a = updatedData[updatedData?.length - 1]?.documents?.length;
          // indexs.push(i + 1);
          // a = allData?.data[i + 1]?.documents?.length;
          // a = 0;
        } else {
          a += allData?.data[i]?.documents?.length;
          updatedData.push(allData?.data[i]);
        }
      }

      // let indexs = [];
      // let a = 0;
      // allMaintenancePlan?.data?.map((el, i) => {
      //   if (a + el?.documents?.length > 9) {
      //     indexs.push(i);
      //     a = 0;
      //   } else {
      //     a += el?.documents?.length;
      //   }
      // });
      setBreakIndexs(indexs);
      setPrintData(updatedData);
      setDupPrintData(updatedData);
      setMaintainancePlan(allMaintenancePlan.data);
      // setting data in useContext
      setActvsPerTypePrintData(updatedData);
      setActvsPerTypeBreakIndexs(indexs);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    setUser(u);
    getAllMaintenancePlan();
  }, [showDrawer]);

  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user"));
    if (u) getMaintencanceSettings(u?._id);
    getAllMaintenanceDiagramData(setMaintananceDiagramData);
    getMaintenanceDepositionData(setDepositionData);
    getMaintenanceReport(setMaintenanceReport);
    getMaintenanceSettings(setMaintenanceSettings);
  }, []);

  useEffect(() => {
    sortContent(
      selectedPoints,
      uniquePropsAndBuilds,
      breakIndexs,
      actvsPerYearBreakIndexs,
      setPageNumbering
    );
  }, [selectedPoints]);

  // useEffect(() => {
  //   uniquePropertyAndBuildings(
  //     maintainancePlan,
  //     value,
  //     setUniquePropsAndBuilds
  //   );
  // }, [value, maintainancePlan]);

  const depData = {
    labels: depositionData?.depositions?.map((elem) => {
      return elem.deposition_year;
    }),
    datasets: [
      {
        label: t("data_settings.rec_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.rec_value_fund;
        }),
        backgroundColor: "lightYellow",
        borderColor: "#FF9A25",
        borderWidth: 2,
      },
      {
        label: t("data_settings.current_deposition"),
        data: depositionData?.depositions?.map((elem) => {
          return elem.curr_value_fund;
        }),
        borderColor: "#413F41",
        backgroundColor: "navy",
        borderWidth: 2,
      },
    ],
  };

  const handleChangeStatus = async (StatusName, documentId) => {
    try {
      let res = await api.patch(
        `/planning_component/maintainance/activites-year-status/${documentId}`,
        { StatusName }
      );
      handleFindClick();
    } catch (error) {
      console.log(error);
    }
  };

  const getMaintencanceSettings = async (id) => {
    const res = await api.get(`/maintenance_settings/${id}`);
    setFormData(res?.data);
  };

  // Detail Modal Functions
  const detailModalClose = () => setDetailModal(false);
  const detailModalShow = (item) => {
    setInitalVal(item);
    setDetailModal(true);
  };

  const filesModal = (item) => {
    setTimeout(() => {
      setInitalVal(item);
      SidePanelService.open(filesSidePanel, {
        handleSubmit,
        initalVal: item,
        handleClose: () => {
          setShowSidePanel(false);
        },
      });
    }, 100);
  };

  const handleSubmit = async (e, data, isCopyItems) => {
    try {
      e.preventDefault();
      if (isCopyItems) {
        data._id = undefined;
        const res = await api.post(
          "/planning_component/maintainance/activitesPerYear-copy",
          data
        );
      } else {
        let res = await api.patch(
          `/planning_component/maintainance/activitesPerYear-edit/${data._id}`,
          data
        );
        let responseItem = maintainancePlan?.map((elem) => {
          if (elem._id == res.data._id) {
            return (elem = res.data);
          } else {
            return elem;
          }
        });
        setMaintainancePlan(responseItem);
      }
      setShowDrawer(!showDrawer);
      setShowSidePanel(false);
    } catch (error) {
      console.log(error);
      toast("Some Error", { type: "error" });
    }
  };

  // Edit Copy Modal
  const handleNewProperty = (item, isCopyItems) => {
    setInitalVal(item);
    setTimeout(() => {
      SidePanelService.open(ActivitesYearSidePanel, {
        handleSubmit,
        initalValue: item,
        isCopyItems,
        handleClose: () => {
          setShowSidePanel(false);
        },
      });
    }, 100);
  };

  const handleShow = (item) => {
    setInitalVal(item);
    setShow(true);
  };

  // Delete Modal Function
  const deleteModalClose = () => {
    setInitalVal(null);
    setShow(false);
  };

  const genratePdf = async () => {
    setPrintLoader(true);
    let pdf = new jsPDF({ orientation: "portrait" });

    pdf.addFileToVFS("PTSans-Regular.ttf", PTSans);
    pdf.addFont("PTSans-Regular.ttf", "PTSans", "normal");

    pdf.addFileToVFS("PTSans-Bold.ttf", PTSansBold); // Assuming PTSansBold contains base64 data of the bold font
    pdf.addFont("PTSans-Bold.ttf", "PTSans", "bold");

    let property_page_num = 0;
    let activities_year_page_num = 0;
    let activites_system_page_num = 0;
    let maintainenace_diagram_page_num = 0;
    let deposition_diagram_page_num = 0;

    // Add header content (without HTML)
    const header = () => {
      pdf.setFont("PTSans", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(100);
      pdf.text("Underhållsplan", pdf.internal.pageSize.width - 5, 5, {
        align: "right",
      }); // Header text
      // pdf.setFont("PTSans", "normal");
      pdf.setFont("PTSans", "bold");
      pdf.setFontSize(14);
      pdf.text(user?.organization, pdf.internal.pageSize.width - 5, 10, {
        align: "right",
      }); // Placeholder for organization name
      pdf.setLineWidth(0.1);
      pdf.setDrawColor(124, 124, 124);
      pdf.line(5, 13, pdf.internal.pageSize.width - 5, 13); // Horizontal line for separator
    };

    // Add footer content (without HTML)
    const footer = (pageNumber) => {
      pdf.setFont("PTSans", "bold");
      pdf.setFontSize(11);
      pdf.setTextColor(128, 128, 128);
      pdf.line(
        10,
        pdf.internal.pageSize.height - 17,
        pdf.internal.pageSize.width - 10,
        pdf.internal.pageSize.height - 17
      ); // Horizontal line
      pdf.text(
        "This maintenance plan was created with the web application JANUS.",
        55,
        pdf.internal.pageSize.height - 10
      );
      pdf.text("More details at", 68, pdf.internal.pageSize.height - 5);

      // Set text color to blue for the URL
      pdf.setTextColor(153, 153, 255);
      pdf.text(
        "www.dinunderhallsplan.se",
        95,
        pdf.internal.pageSize.height - 5
      );
    };

    const getImageAsBase64 = async (imageUrl) => {
      try {
        const response = await api.get(
          `/images/proxy-image?url=${encodeURIComponent(imageUrl)}`,
          {
            responseType: "arraybuffer",
          }
        );
        console.log(response, "response");
        const blob = new Blob([response.data], { type: "image/png" });
        console.log(blob, "blob");
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const addSecondPageContent = () => {
      // Add title
      header();
      footer();
      pdf.setPage(2);
      pdf.setFontSize(22);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(64, 64, 64);
      pdf.text("Innehållsförteckning", pdf.internal.pageSize.width / 2, 40, {
        align: "center",
      });

      // Add dynamic content from selectedPoints
      pdf.setFontSize(17);
      pdf.setFont("PTSans", "normal");
      let yOffset = 60;
      selectedPoints.forEach((el) => {
        if (contentTexts.includes(el)) {
          console.log(property_page_num, el);
          pdf.text(contentTextsVariables[el], 20, yOffset);
          pdf.text(
            el == "propertyAndBuildingData"
              ? `${property_page_num}`
              : el == "maintenanceActivitiesPerYear"
              ? `${activities_year_page_num}`
              : el == "maintenanceActivitiesPerSystem"
              ? `${activites_system_page_num}`
              : el == "maintenanceDiagram"
              ? `${maintainenace_diagram_page_num}`
              : el == "depositionsDiagram"
              ? `${deposition_diagram_page_num}`
              : `${pageNumbering[el]}`,
            pdf.internal.pageSize.width - 40,
            yOffset
          ); // Right align page number
          yOffset += 10;
        }
      });
    };

    const addThirdPageContent = () => {
      // Applied Filters
      header();
      footer();

      if (Object.keys(filterValues).length > 0) {
        pdf.setFontSize(22);
        pdf.setFont("PTSans", "bold");
        pdf.text("Applied Filters", 20, 40);

        let yOffset = 60;
        Object.keys(filterValues).forEach((key) => {
          pdf.setFontSize(14);
          pdf.setFont("PTSans", "normal");
          pdf.text(key, 20, yOffset);
          pdf.text(filterValues[key].join(", "), 100, yOffset);
          yOffset += 10;
        });
      }

      // Plan Settings
      pdf.setFontSize(22);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(0, 0, 0);
      let text = `Plan ${t("property_page.Settings")}`;
      pdf.text(text, pdf.internal.pageSize.width / 2, 40, {
        align: "center",
      });

      const settings = [
        { label: "Namn", value: maintenanceSettings?.version_name },
        { label: "Startår", value: maintenanceSettings?.plan_start_year },
        { label: "Längd, år", value: maintenanceSettings?.plan_duration },
        {
          label: "Generellt påslag, %",
          value: maintenanceSettings?.general_surcharge,
        },
        { label: "Moms, %", value: maintenanceSettings?.vat_percent },
        {
          label: "Årlig uppräkning, %",
          value: maintenanceSettings?.yearly_increase,
        },
        {
          label: "Basår index",
          value: maintenanceSettings?.base_year_increase || "-",
        },
      ];

      let settingsOffset = Object.keys(filterValues).length > 0 ? 100 : 80;
      settings.forEach((setting) => {
        pdf.setFontSize(14);
        pdf.setFont("PTSans", "normal");

        // Set light gray background for the keys column
        pdf.setFillColor(211, 211, 211); // Light gray color
        pdf.rect(18, settingsOffset - 6, 70, 10, "F");

        // Set white background for the values column
        pdf.setFillColor(255, 255, 255); // White color
        pdf.rect(88, settingsOffset - 6, 100, 10, "F");

        // Draw light gray borders around each key-value pair
        pdf.setDrawColor(200, 200, 200); // Light gray color for border
        pdf.rect(18, settingsOffset - 6, 170, 10, "S"); // Outer border for each row

        // Add the text
        pdf.setTextColor(0, 0, 0); // Set text color to black
        pdf.text(setting.label, 20, settingsOffset); // Key
        pdf.text(`${setting.value}`, 90, settingsOffset); // Value

        settingsOffset += 10;
      });
      // settings.forEach((setting, index) => {
      //   pdf.setFontSize(14);
      //   pdf.setFont("PTSans", "normal");
      //   pdf.setFillColor(200, 200, 255); // Light blue background
      //   pdf.rect(18, settingsOffset - 6, 70, 10, "F"); // '
      //   pdf.text(setting.label, 20, settingsOffset);
      //   pdf.text(`${setting.value}`, 100, settingsOffset);
      //   settingsOffset += 10;
      // });
    };

    const addFourthPageContent = () => {
      header();
      pdf.setFontSize(18);
      pdf.setFont("PTSans", "normal");
      pdf.setTextColor(64, 64, 64);
      pdf.text(t("property_page.Property_and_building_data"), 105, 34, {
        align: "center",
      });

      let currentPage = pdf.internal.getNumberOfPages();
      console.log("current page property data", currentPage);
      property_page_num = currentPage;
      pdf.autoTable({
        startY: 40,
        head: [
          [
            t("property_page.Legal Name").toUpperCase(),
            t("common.pages.Address").toUpperCase(),
            "AREA BOA",
            t("property_page.buildings").toUpperCase(),
          ],
        ],
        body:
          filterValues?.properties && filterValues?.properties.length > 0
            ? filterValues?.properties.map((el) => {
                let pfound = value?.find((p) => p?.name === el);
                return [
                  pfound?.legal_name || "",
                  pfound?.street_address || "",
                  pfound?.sum_area_boa || "",
                  pfound?.buildingCodes?.join(", ") || "",
                ];
              })
            : value?.map((el) => [
                el?.legal_name || "",
                el?.street_address || "",
                el?.sum_area_boa || "",
                el?.buildingCodes?.join(", ") || "",
              ]),
        styles: {
          fillColor: "white",
          textColor: "gray",
          fontFamily: "sans-serif",
        },
      });

      footer(4); // Add footer on page 4
    };

    const addFifthPageContent = () => {
      let pageHeight = pdf.internal.pageSize.height; // Height of the page
      let marginBottom = 20; // Margin from the bottom of the page to trigger a new page
      let currentY = 35;

      pdf.setFontSize(18);
      pdf.setFont("sans-serif", "normal");
      let currentPage = pdf.internal.getNumberOfPages();
      console.log("current page property data", currentPage);
      activities_year_page_num = currentPage;
      console.log(printData, "printData");

      printData?.forEach((elem, index) => {
        let currentIndex = index;
        // elem.doucments = [
        //   {
        //     maintenance_activity: "2027",
        //     article: null,
        //     u_system: null,
        //     technical_life: null,
        //     energy_flag: null,
        //     status: "EX. VAT",
        //     total_cost: "875 500",
        //   },
        //   ...elem?.documents,
        // ];
        // Add the parent header for the year (like 2024, 2025, etc.)

        let yearY;
        // if (pdf?.previousAutoTable.finalY > 270) {
        //   pdf.addPage();
        //   header();
        //   footer();
        //   yearY = 35;
        // } else {
        yearY = index > 0 ? pdf?.previousAutoTable.finalY + 5 : 30;
        // }

        let year = `${elem?._id}`;
        let status = switchState
          ? t("common.pages.INC. VAT")
          : t("common.pages.EX. VAT");
        let total_cost = `${elem.totalCost
          ?.toLocaleString()
          .replace(/,/g, " ")}`;
        pdf.setFontSize(11);
        pdf.setFont("sans-serif", "normal");
        pdf.setFillColor("#FFFF88");
        // pdf.text(year, 24, yearY, {
        //   align: "center",
        // });
        // pdf.text(status, 160, yearY, {
        //   align: "center",
        // });
        // pdf.text(total_cost, 185, yearY, {
        //   align: "center",
        // });

        // Adjust for the next element's Y position

        // Render the detailed activity table for each year
        console.log(pdf.previousAutoTable);
        let totalArr = [
          {
            maintenance_activity: year,
            article: "",
            u_system: "",
            technical_life: "",
            energy_flag: "",
            status: status,
            total_cost: total_cost,
          },
        ];
        pdf.autoTable({
          startY: index > 0 ? pdf.previousAutoTable.finalY + 5 : yearY,
          head: totalArr?.map((item) => [
            item.maintenance_activity,
            "         ",
            "         ",
            "         ",
            "         ",
            item.status || "Choose",
            item?.total_cost?.toLocaleString().replace(/,/g, " "),
          ]),
          body: [],
          columnStyles: {
            0: { cellWidth: 35 }, // First column (Activity) has width of 30
            1: { cellWidth: 30 }, // Sixth column (Status) has width of 25
            2: { cellWidth: 30 }, // Seventh column (Total Cost) auto-sizes
            3: { cellWidth: 30 }, // Seventh column (Total Cost) auto-sizes
            4: { cellWidth: 30 }, // Seventh column (Total Cost) auto-sizes
            5: { cellWidth: 30 }, // Seventh column (Total Cost) auto-sizes
            6: { cellWidth: 15 }, // Seventh column (Total Cost) auto-sizes
          },
          styles: {
            fontFamily: "sans-serif",
            fillColor: "#C0C0C0",
            textColor: "black",
          },
        });
        pdf.autoTable({
          startY: pdf.previousAutoTable.finalY + 4,
          head: [
            [
              t("planning_page.activity").toUpperCase(),
              t("planning_page.article").toUpperCase(),
              "SYSTEM",
              t("planning_page.interval").toUpperCase(),
              t("planning_page.flags").toUpperCase(),
              "STATUS",
              t("planning_page.total_cost").toUpperCase(),
            ],
          ],
          body: elem?.documents?.map((item) => [
            item.maintenance_activity,
            item.article,
            item.u_system,
            item.technical_life + " years",
            "",
            "",
            item?.total_cost?.toLocaleString().replace(/,/g, " "),
          ]),
          columnStyles: {
            0: { cellWidth: 35 }, // First column (Activity) has width of 30
            1: { cellWidth: 25 }, // Sixth column (Status) has width of 25
            2: { cellWidth: 20 }, // Seventh column (Total Cost) auto-sizes
            3: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            4: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            5: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            5: { cellWidth: 20 }, // Seventh column (Total Cost) auto-sizes
          },
          styles: {
            fontFamily: "sans-serif",
            fillColor: "white",
            textColor: "black",
          },

          didDrawPage: (data) => {
            header();
            footer();
            // pdf.previousAutoTable.finalY = 35;
            const tableBottomY = pdf.previousAutoTable.finalY;
            // currentY = tableBottomY + 5;
            // // Check if the table is about to exceed the page height, and trigger a new page
            console.log(tableBottomY, pageHeight, marginBottom);
            if (
              tableBottomY >= pageHeight - marginBottom &&
              data?.row?.index === 0
            ) {
              currentIndex = 1;
              // currentY = pdf.previousAutoTable.finalY + 20;
              // pdf.addPage();
              yearY = 35;
            }
          },

          didDrawCell: (data) => {
            console.log(data, "didrawcell");
            if (data.section === "body" && data.column.index === 4) {
              // Column index 4 corresponds to the "FLAGS" column
              const item = elem.documents[data.row.index]; // Get the current row item
              const images = [];

              // Push images based on flags
              if (item?.energy_flag) images.push(leaf_icon);
              if (item?.invest_flag) images.push(money_icon);
              if (item?.risk_flag) images.push(risk_icon);
              if (item?.project_flag) images.push(project_icon);
              if (item?.inspection_flag) images.push(search_icon);

              const startX = data.cell.x + 2; // Adjust the starting X position within the cell
              let currentX = startX; // Track the current X position to place multiple images

              images.forEach((img) => {
                const imgWidth = 3; // Adjust image width
                const imgHeight = 3; // Adjust image height

                // Draw each image within the cell
                pdf.addImage(
                  img,
                  "PNG",
                  currentX,
                  data.cell.y + 2,
                  imgWidth,
                  imgHeight
                );

                currentX += imgWidth + 2; // Adjust spacing between images
              });
            }

            if (data.section === "body" && data.column.index === 5) {
              const item = elem.documents[data.row.index]; // Get the current row item
              let color;

              // Set the color based on the status
              switch (item?.status || "Choose") {
                case "Planerad":
                  color = [32, 35, 85]; // Light blue (RGB values)
                  break;
                case "Akut":
                  color = [255, 0, 0]; // Red
                  break;
                case "Eftersatt":
                  color = [255, 165, 0]; // Orange
                  break;
                case "Beslutad":
                  color = [0, 128, 0]; // Green
                  break;
                case "Utförd":
                  color = [38, 138, 98]; // Indigo
                  break;
                default:
                  color = null; // No color for other statuses
              }

              if (color) {
                pdf.setFillColor(color[0], color[1], color[2]); // Set fill color
                const rectWidth = 4; // Width of the color box
                const rectHeight = 4; // Height of the color box

                // Draw the rectangle before the status text
                pdf.roundedRect(
                  data.cell.x + 2, // Adjust X position within the cell
                  data.cell.y + (data.cell.height - rectHeight) / 2, // Center the box vertically
                  rectWidth,
                  rectHeight,
                  1, // Set the horizontal radius to 2
                  1, // Set the vertical radius to 2
                  "F" // Fill the rectangle
                );
              }

              const textX = data.cell.x + 7;
              pdf.setFontSize(8);
              pdf.text(
                item?.status || "Choose",
                color ? textX : textX - 5,
                data.cell.y + data.cell.height / 3 + 3
              );
            }
          },
        });
      });

      // Footer on page 5
    };

    const renderSixthPagePDF = () => {
      pdf.setFont("sans-serif", "normal");

      const columns = [
        {
          header: t("planning_page.activity").toUpperCase(),
          dataKey: "activity",
        },
        {
          header: t("planning_page.article").toUpperCase(),
          dataKey: "article",
        },
        { header: "SYSTEM", dataKey: "system" },
        {
          header: t("planning_page.interval").toUpperCase(),
          dataKey: "interval",
        },
        { header: t("planning_page.flags").toUpperCase(), dataKey: "flags" },
        { header: "STATUS", dataKey: "status" },
        {
          header: t("planning_page.total_cost").toUpperCase(),
          dataKey: "totalCost",
        },
      ];

      let currentPage = pdf.internal.getNumberOfPages();
      console.log("current page property data", currentPage);
      activites_system_page_num = currentPage;

      // Add a new page (6th page)

      actvsPerTypePrintData.forEach((elem, index) => {
        let yearY = index > 0 ? pdf?.previousAutoTable.finalY + 5 : 30;
        const tableData = elem?.documents?.map((item) => {
          return {
            activity:
              item.maintenance_activity.length <= 73
                ? item.maintenance_activity
                : `${item?.maintenance_activity?.substring(0, 50)}...`,
            article: item.article,
            system: item.start_year,
            interval: `${item.technical_life} ${t("planning_page.years")}`,
            flags: getFlagsIcons(item), // helper function to get flag icons
            status: getStatusLabel(item, t), // helper function to get status label
            totalCost: formatCost(item.total_cost),
          };
        });

        // Insert table header
        pdf.autoTable({
          head: [
            [
              `${elem._id} ${elem.uSystemName}`,
              "",
              "",
              "",
              "",
              "",
              `${formatCost(elem.totalCost)}`,
            ],
          ],
          theme: "plain",
          startY: index > 0 ? pdf.previousAutoTable.finalY + 5 : yearY,
          styles: {
            fontFamily: "sans-serif",
            fillColor: "#C0C0C0",
            textColor: "black",
          },
        });

        // Render table body
        pdf.autoTable({
          head: [columns.map((col) => col.header)],
          body: tableData.map((row) =>
            columns.map((col) =>
              col.dataKey == "status"
                ? ""
                : col.dataKey == "flags"
                ? ""
                : row[col.dataKey]
            )
          ),
          startY: pdf.previousAutoTable.finalY + 4,
          margin: { top: 20 },
          columnStyles: {
            0: { cellWidth: 35 }, // First column (Activity) has width of 30
            1: { cellWidth: 25 }, // Sixth column (Status) has width of 25
            2: { cellWidth: 20 }, // Seventh column (Total Cost) auto-sizes
            3: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            4: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            5: { cellWidth: 25 }, // Seventh column (Total Cost) auto-sizes
            5: { cellWidth: 20 }, // Seventh column (Total Cost) auto-sizes
          },
          styles: {
            fontFamily: "sans-serif",
            fillColor: "white",
            textColor: "black",
          },
          didDrawPage: (data) => {
            // Optional: Add page numbers or footer here
            header();
            footer();
          },
          didDrawCell: (data) => {
            console.log(data, "didrawcell");
            if (data.section === "body" && data.column.index === 4) {
              // Column index 4 corresponds to the "FLAGS" column
              const item = elem.documents[data.row.index]; // Get the current row item
              const images = [];

              // Push images based on flags
              if (item?.energy_flag) images.push(leaf_icon);
              if (item?.invest_flag) images.push(money_icon);
              if (item?.risk_flag) images.push(risk_icon);
              if (item?.project_flag) images.push(project_icon);
              if (item?.inspection_flag) images.push(search_icon);

              const startX = data.cell.x + 2; // Adjust the starting X position within the cell
              let currentX = startX; // Track the current X position to place multiple images

              images.forEach((img) => {
                const imgWidth = 3; // Adjust image width
                const imgHeight = 3; // Adjust image height

                // Draw each image within the cell
                pdf.addImage(
                  img,
                  "PNG",
                  currentX,
                  data.cell.y + 2,
                  imgWidth,
                  imgHeight
                );

                currentX += imgWidth + 2; // Adjust spacing between images
              });
            }

            if (data.section === "body" && data.column.index === 5) {
              const item = elem.documents[data.row.index]; // Get the current row item
              let color;

              // Set the color based on the status
              switch (item?.status || "Choose") {
                case "Planerad":
                  color = [32, 35, 85]; // Light blue (RGB values)
                  break;
                case "Akut":
                  color = [255, 0, 0]; // Red
                  break;
                case "Eftersatt":
                  color = [255, 165, 0]; // Orange
                  break;
                case "Beslutad":
                  color = [0, 128, 0]; // Green
                  break;
                case "Utförd":
                  color = [38, 138, 98]; // Indigo
                  break;
                default:
                  color = null; // No color for other statuses
              }

              if (color) {
                pdf.setFillColor(color[0], color[1], color[2]); // Set fill color
                const rectWidth = 4; // Width of the color box
                const rectHeight = 4; // Height of the color box

                // Draw the rectangle before the status text
                pdf.roundedRect(
                  data.cell.x + 2, // Adjust X position within the cell
                  data.cell.y + (data.cell.height - rectHeight) / 2, // Center the box vertically
                  rectWidth,
                  rectHeight,
                  1, // Set the horizontal radius to 2
                  1, // Set the vertical radius to 2
                  "F" // Fill the rectangle
                );
              }

              const textX = data.cell.x + 7;
              pdf.setFontSize(8);
              pdf.text(
                item?.status || "Choose",
                color ? textX : textX - 5,
                data.cell.y + data.cell.height / 3 + 3
              );
            }
          },
        });
      });
    };

    function getFlagsIcons(item) {
      let flags = "";
      if (item.energy_flag) flags += "🌿"; // Energy Flag Icon
      if (item.invest_flag) flags += "💰"; // Invest Flag Icon
      if (item.risk_flag) flags += "⚠️"; // Risk Flag Icon
      if (item.project_flag) flags += "📋"; // Project Flag Icon
      if (item.inspection_flag) flags += "🔍"; // Inspection Flag Icon
      return flags;
    }

    function getStatusLabel(item, t) {
      switch (item.status) {
        case "Planerad":
          return t("plan_color");
        case "Akut":
          return t("akut_color");
        case "Eftersatt":
          return t("efter_color");
        case "Beslutad":
          return t("beslu_color");
        case "Utförd":
          return t("utford_color");
        default:
          return t("common.pages.choose");
      }
    }

    function formatCost(cost) {
      return cost?.toLocaleString()?.replace(/,/g, " ");
    }

    // if (selectedPoints.includes("coverPage")) {
    //   addFirstPageContent();
    // }
    if (selectedPoints.includes("tableOfContent")) {
      pdf.addPage();
    }
    if (selectedPoints.includes("planSettings")) {
      pdf.addPage();
      addThirdPageContent();
    }

    if (selectedPoints.includes("myCustomText")) {
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      pdf.addPage();
      // console.log("promise");

      // Convert the div to an image using html2canvas
      const convertHtmlToImage = async (element) => {
        const canvas = await html2canvas(element, {
          scale: 1, // Increase the scale for better quality
          useCORS: true, // Allows cross-origin images
        });
        return canvas;
      };

      // Get the reference to your content
      const content = htmlContentRef.current;
      content.style.visibility = "visible";
      content.style.position = "absolute"; // Keep it out of flow
      content.style.zIndex = "-1";

      // Capture the image of the content
      const canvas = await convertHtmlToImage(content);

      // Calculate dimensions for PDF
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20; // Adjust to have some padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Calculate how many pages we need
      const totalPages = Math.ceil(imgHeight / (pdfHeight - 50)); // 50px reserved for header and footer

      // Iterate through pages and add content
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        if (pageNumber > 1) {
          pdf.addPage();
        }

        header();

        const overlap = 5; // Number of pixels to overlap between pages

        const yOffset =
          (pageNumber - 1) *
          ((pdfHeight - 30 + overlap) * (canvas.width / imgWidth));

        // Create a new canvas for the current page slice
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = (pdfHeight - 30) * (canvas.width / imgWidth);
        const ctx = pageCanvas.getContext("2d");

        // Draw the relevant portion of the original canvas on the new page canvas
        ctx.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          (pdfHeight - 30 + overlap) * (canvas.width / imgWidth),
          0,
          0,
          canvas.width,
          (pdfHeight - 30 + overlap) * (canvas.width / imgWidth)
        );

        // Convert the page canvas to image data
        const imgData = pageCanvas.toDataURL("image/png", 0.3);

        // Add the image to the PDF
        pdf.addImage(
          imgData,
          "PNG",
          10,
          20, // Adjust starting position below header
          imgWidth,
          pdfHeight - 40
        );

        footer();
      }

      // Save the generated PDF
      if (
        !selectedPoints.includes("depositionsDiagram") &&
        !selectedPoints.includes("maintenanceDiagram")
      ) {
        // pdf.save("large-content.pdf");
      }
      content.style.visibility = "hidden";
      content.style.visibility = "hidden";

      // await pdf.html(htmlContentRef.current, {
      //   callback: () => {
      //     const endPage = pdf.internal.getCurrentPageInfo().pageNumber;
      //     // const endPage = pdf.internal.getCurrentPageInfo().pageNumber; // Get the last page created by the content

      //     // Add headers and footers only to the pages affected by the HTML content
      //     // console.log(startPage, endPage);
      //     // pdf.addPage();
      //     // pdf.setPage(2);

      //     for (let pageNumber = 4; pageNumber <= endPage; pageNumber++) {
      //       pdf.setPage(pageNumber);
      //       header();
      //       footer();
      //     }

      //     if (
      //       !selectedPoints.includes("depositionsDiagram") &&
      //       !selectedPoints.includes("maintenanceDiagram")
      //     ) {
      //       pdf.save("large-content.pdf");
      //     }
      //   },

      //   x: 7,
      //   y: 10,
      //   width: 190, // Adjust width as per your content
      //   windowWidth: 800,

      //   // Set the width for the rendering context
      //   margin: [10, 7, 15, 10], // Optional margin
      //   // autoPaging: true, // Ensure content goes to the next page
      // });
      // pdf.addPage();
    }

    if (selectedPoints.includes("propertyAndBuildingData")) {
      pdf.addPage();
      addFourthPageContent();
    }
    if (selectedPoints.includes("maintenanceActivitiesPerYear")) {
      pdf.addPage();
      addFifthPageContent();
    }
    if (selectedPoints.includes("maintenanceActivitiesPerSystem")) {
      pdf.addPage();
      renderSixthPagePDF();
    }

    if (
      selectedPoints.includes("maintenanceDiagram") ||
      selectedPoints.includes("depositionsDiagram") ||
      selectedPoints.includes("coverPage")
    ) {
      let text;
      let chartElement = chartRef.current;

      if (selectedPoints.includes("coverPage")) {
        if (pdf.getNumberOfPages() === 0) {
          pdf.addPage(); // If no pages exist, add a new page
        }
        pdf.setPage(1);
        // Add image
        header();
        footer();
        console.log(maintenanceReport);
        const imageUrl = maintenanceReport?.image || constructionImg;
        const imageWidth = 100; // Width in mm (approximately A4 size width)
        const imageHeight = 100; // Height in mm for scaling
        const marginY = 60; // Top margin
        console.log(imageUrl);

        const imgData = await getImageAsBase64(imageUrl);

        console.log(imgData, "base64");

        // let imgCanvas = await html2canvas(imgContent);
        // const imgData = imgCanvas.toDataURL("image/png");
        // Calculate dimensions to fit the A4 size
        // console.log(returnedB64, "returnedB64");

        pdf.addImage(
          imgData ? imgData : constructionImg,
          "PNG",
          (pdf.internal.pageSize.width - imageWidth) / 2,
          marginY,
          imageWidth,
          imageHeight
        );

        console.log("after add image");

        pdf.setFontSize(24);
        pdf.setFont("PTSans", "bold");
        pdf.setTextColor(64, 64, 64);
        pdf.text(
          "Underhållsplan",
          pdf.internal.pageSize.width / 2,
          marginY + imageHeight + 20,
          { align: "center" }
        );

        pdf.setFontSize(18);
        const startYear =
          filterValues.start_year || maintenanceSettings.plan_start_year;
        const endYear =
          maintenanceSettings.plan_start_year +
          maintenanceSettings.plan_duration;
        pdf.text(
          `${startYear} - ${endYear}`,
          pdf.internal.pageSize.width / 2,
          marginY + imageHeight + 40,
          { align: "center" }
        );
        if (
          !selectedPoints.includes("maintenanceDiagram") &&
          !selectedPoints.includes("depositionsDiagram")
        ) {
          if (selectedPoints.includes("tableOfContent")) {
            addSecondPageContent();
          }
          pdf.save("download");
          setPrintLoader(false);
        }
      }

      if (selectedPoints.includes("maintenanceDiagram")) {
        pdf.addPage();
        let currentPage = pdf.internal.getNumberOfPages();
        console.log("current page property data", currentPage);
        maintainenace_diagram_page_num = currentPage;
        chartElement.style.visibility = "visible";
        chartElement.style.position = "absolute"; // Keep it out of flow
        chartElement.style.zIndex = "-1"; // Send it to the back

        let canvas = await html2canvas(chartElement);
        let maintainenceImageData = canvas.toDataURL("image/png");
        let imgWidth = 190; // PDF page width minus margins
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.setFontSize(16);
        text = t("property_page.Maintenance_diagram");
        pdf.text(text, 105, 34, { align: "center" });
        pdf.addImage(maintainenceImageData, "PNG", 10, 50, imgWidth, imgHeight);

        if (!selectedPoints.includes("depositionsDiagram")) {
          if (selectedPoints.includes("tableOfContent")) {
            addSecondPageContent();
          }
          chartElement.style.visibility = "hidden";
          pdf.save("download.pdf");
          setPrintLoader(false);
        }
      }

      if (selectedPoints.includes("depositionsDiagram")) {
        pdf.addPage();
        let currentPage = pdf.internal.getNumberOfPages();
        console.log("current page property data", currentPage);
        deposition_diagram_page_num = currentPage;
        let lineChartElement = lineChartRef.current;
        lineChartElement.style.visibility = "visible";
        lineChartElement.style.position = "absolute"; // Keep it out of flow
        lineChartElement.style.zIndex = "-1";

        let canvas2 = await html2canvas(lineChartElement);
        const imgData2 = canvas2.toDataURL("image/png");

        const imgWidth2 = 190; // PDF page width minus margins
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width; // Maintain aspect ratio

        pdf.setFontSize(16);
        text = "Diagram avsättningar";
        pdf.text(text, 105, 34, { align: "center" });
        pdf.addImage(imgData2, "PNG", 10, 50, imgWidth2, imgHeight2);
        if (selectedPoints.includes("tableOfContent")) {
          addSecondPageContent();
        }
        // if (!selectedPoints.includes("myCustomText")) {
        pdf.save("download.pdf"); // Save PDF here after chart is added
        setPrintLoader(false);
        lineChartElement.style.visibility = "hidden";
        chartElement.style.visibility = "hidden";
        // }
      }
    }

    if (
      selectedPoints.includes("maintenanceDiagram") ||
      selectedPoints.includes("depositionsDiagram") ||
      selectedPoints.includes("coverPage")
    ) {
    } else {
      if (selectedPoints.includes("tableOfContent")) {
        addSecondPageContent();
      }
      pdf.save("download");
      setPrintLoader(false);
      // pdf.setPage(1);
    }
  };

  // const genratePdf = useReactToPrint({
  //   content: () => printRef.current,
  //   documentTitle: "Activities Per Type",
  //   onAfterPrint: () => {
  //     setShowMenuCol(true);
  //   },
  // });

  const handlePrintClick = () => {
    setShowMenuCol(false);
    setTimeout(() => {
      genratePdf();
      handleChangeAction(null);
    }, [100]);
  };

  const handleChangePoint = (point) => {
    if (selectedPoints?.includes(point)) {
      setSelectedPoints(selectedPoints?.filter((el) => el !== point));
    } else {
      setSelectedPoints([...selectedPoints, point]);
    }
  };

  const handleOpenModal = () => {
    setPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setSelectedPoints([]);
    handleChangeAction(null);
    setPrintModal(false);
  };
  useEffect(() => {
    if (printItem && currReprtTab == "activitestype") {
      handleChangePoint("maintenanceActivitiesPerSystem");
      handlePrintClick();
    }
  }, [printItem]);

  useEffect(() => {
    if (createReport && currReprtTab === "activitestype") {
      handleOpenModal();
    }
  }, [createReport]);
  // if (!allFilters) return <Loader />;

  const calculatePercentage = () => {
    let percent = formData?.general_surcharge;
    if (percent) {
      let updatedPlan = maintainancePlan?.map((elem) => {
        return {
          ...elem,
          totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
          documents: elem.documents.map((item) => {
            if (item?.total_cost) {
              return {
                ...item,
                total_cost:
                  (parseInt(item?.total_cost) * percent) / 100 +
                  parseInt(item?.total_cost),
              };
            } else {
              return item;
            }
          }),
        };
      });
      // let yearlyIncrease = 0;
      updatedPlan = updatedPlan?.map((elem) => {
        if (
          elem?._id > formData?.base_year_increase &&
          formData?.yearly_increase
        ) {
          let total_cost = elem?.totalCost;
          let factor = formData?.yearly_increase / 100 + 1;
          let differenece = elem?._id - formData?.base_year_increase;

          let result = Math.pow(factor, differenece);
          console.log(elem, "elem");
          console.log(result, "result");
          // yearlyIncrease = yearlyIncrease + formData?.yearly_increase;
          return {
            ...elem,
            totalCost: result * total_cost,
            documents: elem.documents.map((item) => {
              if (item?.total_cost) {
                total_cost = item?.total_cost;
                factor = formData?.yearly_increase / 100 + 1;
                differenece = elem?._id - formData?.base_year_increase;
                result = Math.pow(factor, differenece);
                return {
                  ...item,
                  total_cost: result * total_cost,
                };
              } else {
                return item;
              }
            }),
          };
        } else {
          return elem;
        }
      });
      setMaintainancePlan(updatedPlan);
      setDupMaintainancePlan(updatedPlan);
    }
  };

  const addVAT = (data, percent) => {
    let updatedPlan = data?.map((elem) => {
      return {
        ...elem,
        totalCost: (elem?.totalCost * percent) / 100 + elem?.totalCost,
        documents: elem.documents.map((item) => {
          if (item?.total_cost) {
            return {
              ...item,
              total_cost:
                (parseInt(item?.total_cost) * percent) / 100 +
                parseInt(item?.total_cost),
            };
          } else {
            return item;
          }
        }),
      };
    });
    return updatedPlan;
  };

  useEffect(() => {
    if (formData && maintainancePlan.length > 0 && calculation) {
      calculatePercentage();
      setCalculation(false);
    }
  }, [formData, maintainancePlan]);

  const handleChangeSwitch = (e) => {
    setSwitchState(e);
    if (e === true && formData?.vat_percent) {
      let percent = formData.vat_percent;

      let updatedPlan = addVAT(maintainancePlan, percent);
      let updatedPrintData = addVAT(printData, percent);
      setMaintainancePlan(updatedPlan);
      setPrintData(updatedPrintData);
      setActvsPerTypePrintData(updatedPrintData);
    } else {
      setMaintainancePlan(dupMaintainancePlan);
      setActvsPerTypePrintData(dupPrintData);
      setPrintData(dupPrintData);
    }
  };

  return (
    <>
      {/* Filter Code */}
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        status={true}
      />
      <div className="side_paneL_root_main">
        {showSidePanel && <SidePanelRoot />}
      </div>
      {loading ? (
        <div style={{ marginBottom: "1rem" }}>
          <Loader />
        </div>
      ) : (
        <div className="table_scroll">
          <Table>
            <thead>
              <tr className="activites_header">
                <th>{t("planning_page.activity")}</th>
                <th>{t("planning_page.article")}</th>
                <th> {t("planning_page.Year")} </th>
                <th>{t("planning_page.interval")}</th>
                <th className="flags_header">{t("planning_page.flags")}</th>
                <th>STATUS</th>
                <th>
                  <div className="vat_switch">
                    <Switch
                      checked={switchState}
                      setChecked={setSwitchState}
                      onChange={handleChangeSwitch}
                      text={
                        !switchState
                          ? t("common.pages.EX. VAT")
                          : t("common.pages.INC. VAT")
                      }
                    />
                  </div>
                  <div>{t("planning_page.total_cost")}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="activites_year_table_main">
              {maintainancePlan?.map((elem) => (
                <>
                  <tr className="activites_start_year activites_year_cost_main">
                    <td> {`${elem._id} ${elem.uSystemName}`} </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {`${elem?.totalCost}`?.length <= 4
                        ? elem.totalCost
                        : elem.totalCost?.toLocaleString()}
                    </td>
                    <td></td>
                  </tr>
                  {elem?.documents?.map((item) => (
                    <tr className="activites_start_year activites_activity">
                      <td className="reportYearTD activiCol">
                        <div style={{ textWrap: "wrap" }}>
                          {item.maintenance_activity?.length <= 73
                            ? item.maintenance_activity
                            : `${item?.maintenance_activity?.substring(
                                0,
                                73
                              )}...`}
                          {/* {item.maintenance_activity} */}
                        </div>
                      </td>
                      <td className={"reportYearTD"}>{item.article}</td>
                      <td className={"reportYearTD"}>{item.start_year}</td>
                      <td className={"reportYearTD"}>
                        {item.technical_life + " " + t("planning_page.years")}{" "}
                      </td>
                      <td className={"reportYearTD flags_row"}>
                        {item.energy_flag && (
                          <img
                            src={leaf_icon}
                            alt="leaf-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.invest_flag && (
                          <img
                            src={money_icon}
                            alt="money-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.risk_flag && (
                          <img
                            src={risk_icon}
                            alt="risk-icon"
                            className={"leaf_img"}
                          />
                        )}
                        {item.project_flag && (
                          <img
                            src={project_icon}
                            alt="project-icon"
                            className={"leaf_img"}
                          />
                        )}

                        {item.inspection_flag && (
                          <img
                            src={search_icon}
                            alt="search-icon"
                            className={"leaf_img"}
                          />
                        )}
                      </td>
                      <td className="reportYearTD">
                        <Dropdown className={"dropdown_year"}>
                          <Dropdown.Toggle className="activites_year_dropdown activtesYear_dropdown_btn activites_dropdown">
                            <div className="status_color_main">
                              {item?.status === "Planerad" ? (
                                <div className="plan_color_div dropdown_icon plan_color"></div>
                              ) : item?.status === "Akut" ? (
                                <div className="plan_color_div dropdown_icon akut_color"></div>
                              ) : item?.status === "Eftersatt" ? (
                                <div className="plan_color_div dropdown_icon efter_color"></div>
                              ) : item?.status === "Beslutad" ? (
                                <div className="plan_color_div dropdown_icon beslu_color"></div>
                              ) : item?.status === "Utförd" ? (
                                <div className="plan_color_div dropdown_icon utford_color"></div>
                              ) : null}
                              {!item.status || item.status === "Choose"
                                ? t("common.pages.choose")
                                : item.status}
                            </div>
                            <FaCaretDown />
                          </Dropdown.Toggle>
                          <div className="dropdown_menu_main">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Planerad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon plan_color"></div>
                                Planerad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Akut", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon akut_color"></div>
                                Akut
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Eftersatt", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon efter_color"></div>
                                Eftersatt
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Beslutad", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon beslu_color"></div>
                                Beslutad
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleChangeStatus("Utförd", item._id)
                                }
                                className="activitesYear_dropdown_menu_item plan_main"
                              >
                                <div className="plan_color_div dropdown_icon utford_color"></div>
                                Utförd
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </td>
                      <td className={"reportYearTD"}>
                        {`${item.total_cost}`?.length <= 4
                          ? item.total_cost
                          : item.total_cost?.toLocaleString()}
                      </td>
                      <td>
                        <Dropdown className="dropdown_year" drop={"left"}>
                          <Dropdown.Toggle className="activites_year_dropdown">
                            <BsThreeDots />
                          </Dropdown.Toggle>
                          <div className="dropdown_menu_main">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => detailModalShow(item)}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.details")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowSidePanel(true);
                                  filesModal(item);
                                }}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.files")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowSidePanel(true);
                                  handleNewProperty(item, true);
                                }}
                                className="Year_edit_menu_item"
                              >
                                {t("planning_page.copy")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="Year_edit_menu_item"
                                onClick={() => {
                                  setShowSidePanel(true);
                                  handleNewProperty(item, false);
                                }}
                              >
                                {t("planning_page.edit")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="Year_edit_menu_item"
                                onClick={() => handleShow(item)}
                              >
                                {t("planning_page.delete")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </Table>
          <div
            ref={htmlContentRef}
            style={{ visibility: "hidden", position: "absolute", zIndex: "-1" }}
            dangerouslySetInnerHTML={{ __html: maintenanceReport?.value }}
          />
          <div
            className="maintancne_diagram"
            ref={chartRef}
            style={{ visibility: "hidden", position: "absolute", zIndex: "-1" }}
            // style={{ display: "none" }}
          >
            <Bar data={maintananceDiagramData} options={options} />
          </div>
          <div>
            <div
              className="maintancne_diagram"
              ref={lineChartRef}
              style={{
                visibility: "hidden",
                position: "absolute",
                zIndex: "-1",
              }}
            >
              <Line data={depData} options={depOptions} />
            </div>
          </div>
        </div>
      )}

      {/* Table For Prinintg ... */}
      <div
        ref={printRef}
        className={showMenuCol ? "displayNone" : ""}
        style={{ margin: "0px 45px 0px 45px" }}
      >
        <PrintData
          selectedPoints={selectedPoints}
          maintenanceReport={maintenanceReport}
          pageNumbering={pageNumbering}
          filterValues={filterValues}
          maintenanceSettings={maintenanceSettings}
          uniquePropsAndBuilds={uniquePropsAndBuilds}
          maintananceDiagramData={maintananceDiagramData}
          options={options}
          user={user}
          printData={actvsPerYearPrintData}
          breakIndexs={actvsPerYearBreakIndexs}
          actvsPerTypeBreakIndexs={breakIndexs}
          actvsPerTypePrintData={printData}
          depositionData={depositionData}
          depOptions={depOptions}
          allProperties={value}
        />
      </div>

      {/* Delete Modal */}
      {show && (
        <DeleteModal
          deleteModalClose={deleteModalClose}
          show={show}
          initalVal={initalVal}
          setMaintainancePlan={setMaintainancePlan}
          maintainancePlan={maintainancePlan}
          activitesType={"activitesType"}
        />
      )}

      {/* Detail Modal */}
      {detailModal && (
        <DetailModal
          detailModalClose={detailModalClose}
          detailModal={detailModal}
          initalVal={initalVal}
        />
      )}

      {/* Print Modal */}
      <PrintModal
        show={printModal}
        loader={printLoader}
        setPrintModal={setPrintModal}
        handleChangeAction={handleChangeAction}
        handleChangePoint={handleChangePoint}
        handlePrintClick={handlePrintClick}
        setSelectedPoints={setSelectedPoints}
        handleClosePrintModal={handleClosePrintModal}
      />
    </>
  );
};

export default ActivitesType;
