import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import {
  Nav,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import Logo from "../../assets/img/janus.png";
import ReactHero from "../../assets/img/technologies/react-hero-logo.svg";
import { useTranslation } from "react-i18next";
import CreateNewDrawer from "./CreateNewDrawer";

const DataSettingSidebar = (props = {}) => {
  const { sidebarShow, NavItem } = props;
  const [show, setShow] = useState(false);
  const [activeItem, setActiveItem] = useState("property");

  const { t } = useTranslation();

  const history = useHistory();

  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);

  const handleItemClick = (itemName, path) => {
    setActiveItem(itemName);
    history.push(path);
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className={`${
          sidebarShow ? "navbar-theme-primary px-4 d-md-none" : "d-none"
        }`}
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={Logo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`${
            !sidebarShow
              ? "hidden"
              : `collapse ${showClass} sidebar d-md-block bg-primary text-white`
          }`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-end justify-content-md-center pb-4">
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <span class="material-symbols-outlined">close</span>
              </Nav.Link>
            </div>

            <Link
              className="d-flex align-items-center justify-content-center  cursor-pointer"
              style={{ cursor: "pointer", width: "100%", marginTop: "9%" }}
              to="/"
            >
              <Image src={Logo} width={50} height={50} />
              {/* <p style={{ marginLeft: "7px" }} className="my-au   to">
                  Janus
                </p> */}
            </Link>

            <Nav className="flex-column pt-3 pt-md-4">
              {/* Create Drop down */}
              <div className="create_home_drawer">
                <CreateNewDrawer />
              </div>
              <div className="property_image_main" style={{ width: "8.2vw" }}>
                <span class="material-symbols-outlined">settings</span>
                {t("common.sidebar.data_settings")}
              </div>
              <div className="setting_property_main">
                <div className="setting_Item">
                  <div
                    className={
                      activeItem === "property"
                        ? "property_image_main setting_property"
                        : "property_image_main setting_property disactiveItem"
                    }
                    style={{ width: "4.5vw" }}
                    onClick={() =>
                      handleItemClick("property", "/datasetting/property")
                    }
                  >
                    {/* <span class="material-symbols-outlined">settings</span> */}
                    {t("common.pages.Property")}
                  </div>
                </div>
                <div className="setting_Item">
                  <div
                    className={
                      activeItem === "supervision"
                        ? "property_image_main setting_property setting_Item"
                        : "property_image_main setting_property disactiveItem setting_Item"
                    }
                    style={{ width: "5.5vw" }}
                    onClick={() =>
                      handleItemClick("supervision", "/datasetting/supervision")
                    }
                  >
                    {/* <span class="material-symbols-outlined">settings</span> */}
                    {t("common.sidebar.supervision")}
                  </div>
                </div>
                <div className="setting_Item">
                  <div
                    className={
                      activeItem === "maintenance"
                        ? "property_image_main setting_property setting_Item"
                        : "property_image_main setting_property disactiveItem setting_Item"
                    }
                    style={{ width: "6.2vw" }}
                    onClick={() =>
                      handleItemClick("maintenance", "/datasetting/maintenance")
                    }
                  >
                    {/* <span class="material-symbols-outlined">settings</span> */}
                    {t("common.sidebar.maintainence")}
                  </div>
                </div>
              </div>
              <NavItem
                title={t("common.sidebar.go_back")}
                link={Routes.DashboardOverview.path}
                icon={"arrow_back"}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default DataSettingSidebar;
