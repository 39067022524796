import { Image, Button } from "@themesberg/react-bootstrap";
import React from "react";
import Logo from "../../assets/img/janus.png";
import { t } from "i18next";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
const Navbar = () => {
  const history = useHistory();
  return (
    <div className="help-resources-navBar">
      <Image src={Logo} className="navLogo" />
      <div>
        <Link to={"/help-resources"} style={{ color: "white" }}>
          {t("common.pages.Help Resources")}
        </Link>
      </div>
      <a href="#" className="link">
        <Link to={`/help-resources-articles`} style={{ color: "white" }}>
          {t("common.pages.Articles")}
        </Link>
      </a>
      <a href="#" className="link">
        <Link to={"/help-resources/video-handler"} style={{ color: "white" }}>
          {t("common.pages.Video tutorials")}
        </Link>
      </a>
    </div>
  );
};

export default Navbar;
