import React, { useState } from "react";
import "./PropertyImport.css";
import { Button, Form } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PropertyImport = ({
  setStep,
  step,
  setProperties,
  setStopStep,
  setCsvFile,
}) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);

  const checkDataValidation = (data) => {
    let fileCorrect = true;
    let result = data.map((property) => {
      const { property_code, legal_name, name, buildingsArray } = property;
      if (property_code && legal_name && name && buildingsArray.length > 0) {
        const building = buildingsArray[0];
        const { building_code, building_name } = building;
        if (building_code && building_name) {
          return property;
        } else {
          fileCorrect = false;
          return toast(
            t(
              "common.pages.The file could not be read, please check that it follows the template"
            ),
            { type: "error" }
          );
        }
      } else {
        fileCorrect = false;
        return toast(
          t(
            "common.pages.The file could not be read, please check that it follows the template"
          ),
          { type: "error" }
        );
      }

      // return null;
    });
    if (fileCorrect) {
      return result;
    } else {
      return false;
    }
    // return data?.map((elem) => {
    //   if (
    //     elem?.property_code &&
    //     elem?.legal_name &&
    //     elem?.name &&
    //     elem?.building_code &&
    //     elem?.building_name
    //   ) {
    //     return elem;
    //   } else {
    //     return toast("Please Fill Required Fields!", { type: "error" });
    //   }
    // });
  };

  const readFile = (selectedFile) => {
    setCsvFile(selectedFile);
    const user = JSON.parse(localStorage.getItem("user"));
    let f = selectedFile;
    const reader = new FileReader();
    reader.onload = async (evt) => {
      //   / Parse data /
      const XLSX = await import("xlsx");
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      //   / Get first worksheet /
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      //   / Convert array of arrays /
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let removedElement = data.shift();
      //   / Update state /
      let allData;
      console.log(data);
      let updateData = data?.filter((subArray) => subArray?.length > 0);

      allData = updateData?.map((arr) => {
        return {
          tenantId: user?._id,
          property_code: arr[10],
          legal_name: arr[11]?.toUpperCase(),
          name: arr[12]?.toUpperCase(),
          buildingsArray: [
            {
              tenantId: user?._id,
              building_code: arr[0],
              building_name: arr[1]?.toUpperCase(),
              street_address: arr[2]?.toUpperCase(),
              zip_code: arr[3],
              city: arr[4]?.toUpperCase(),
              construction_year: arr[5],
              area_boa: arr[6],
              area_loa: arr[7],
              area_bra: arr[8],
              area_bta: arr[9],
              property_code: arr[10],
            },
          ],
        };
      });
      console.log("allData", allData);
      let validatedData = checkDataValidation(allData);
      if (validatedData !== false) {
        setProperties(validatedData);
        setStopStep("PropertyTable");
        setStep(13);
      }
    };
    reader.readAsBinaryString(f);
    document.getElementById("propImport").value = "";
  };

  const handleChangeFile = (e) => {
    readFile(e.target.files[0]);
  };

  const hanldeBack = () => {
    setStopStep(null);
    setStep(3);
    setCsvFile(null);
  };

  // Drag and Drop Handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      readFile(file);
    }
  };

  return (
    <>
      <span
        class="material-symbols-outlined step_arrow_back"
        onClick={hanldeBack}
      >
        arrow_back
      </span>
      <div className="maintenance_main">
        <p className="maintenance_plan_head">
          {t("common.pages.Property data from file")}
        </p>
        <Form.Control
          type="file"
          name="image"
          id="propImport"
          onChange={(e) => handleChangeFile(e)}
          style={{ display: "none" }}
          accept=".xlsx,.csv"
          multiple={false}
        />
        <div
          className={`csv_uploader_main ${isDragging ? "dragging" : ""}`}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label className="csv_uploader" for="propImport">
            <span class="material-symbols-outlined csv_uploader_icon">
              add_notes
            </span>
            <p className="csv_uploader_head">
              {t("common.pages.Select a CSV or Excel file to import")}
            </p>
            <p className="csv_uploader_head csv_drag_head">
              {t("common.pages.or drag and drop it here")}
            </p>
          </label>
        </div>
        <p className="maintenance_plan_head missing_prop_head">
          {t(
            "common.pages.If you are missing the template, you can download it"
          )}{" "}
          <a href="https://janus-uploads.s3.eu-north-1.amazonaws.com/1725057753161-property_template.xlsx">
            {t("common.pages.here!")}
          </a>
        </p>
      </div>
    </>
  );
};

export default PropertyImport;
