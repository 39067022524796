import React, { useEffect, useState } from "react";
import DisabledInputBox from "components/common/InputBox";
import {
  SidePanel,
  SidePanelBody,
  SidePanelFooter,
  SidePanelHeader,
} from "components/common/SidePanel";
import { CreateNewPlanning, EditPlanningById } from "lib/PlanningLib";
import CheckBox from "components/common/CheckBox";
import { usePlanningContextCheck } from "context/SidebarContext/PlanningContextCheck";
import InputBoxDropDown from "components/common/InputBoxDropDown";
import { GetSingleMaintainceItemByArticleCode } from "lib/MaintainceItemLib";
import { GetSearchUSystems } from "lib/USystemsLib";
import Button from "components/common/Button";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Form, Row, Table } from "@themesberg/react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
// import plan_icon from "assets/img/report/plan_dropdown.png";
// import akut_icon from "assets/img/report/akut_dropdown.png";
// import efter_icon from "assets/img/report/efter_dropdown.png";
// import beslu_icon from "assets/img/report/Beslu_dropdown.png";
// import Utford_icon from "assets/img/report/utford_dropdown.png";
import api from "api";
import { useHistory } from "react-router-dom";
import CustomModal from "components/common/Modals/customModal";

const NewItemPlanningModal = ({
  close,
  newItem,
  handleClose,
  modifyItem,
  id,
  defaultValue = {},
}) => {
  const history = useHistory();
  const [data, setData] = useState(defaultValue);
  const [articleCodeChange, setArticleCodeChange] = useState();
  const [systemCodeChange, setSystemCodeChange] = useState();

  const [maintenanceSetting, setMaintenanceSetting] = useState(null);

  const [checkBoxState, setCheckBoxState] = useState(true);

  const [planCount, setPlanCount] = useState(null);

  // Status State
  const [status, setStatus] = useState(null);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [state, setState] = useState(null);
  const [text, setText] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [size, setSize] = useState("lg");

  const { value: articleCodeData } = GetSingleMaintainceItemByArticleCode(
    articleCodeChange || undefined,
    {},
    [articleCodeChange]
  );

  const { value: systemCodeData } = GetSearchUSystems(
    systemCodeChange || undefined,
    {},
    [systemCodeChange]
  );

  useEffect(() => {
    setAllArticleCodeData(articleCodeData);
    setAllSystemCodeData(systemCodeData);
  }, [articleCodeData, systemCodeData]);

  const [allArticleCodeData, setAllArticleCodeData] = useState([]);
  const [allSystemCodeData, setAllSystemCodeData] = useState([]);

  const { buildingChange, planningChange, setReloadCreateEdit } =
    usePlanningContextCheck();
  const handleSubmit = async (e) => {
    if (status) {
      data.status = status;
    } else {
      data.status = "Planerad";
    }
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"))?._id;
    data.total_cost = data?.price_per_unit * data.quantity || 0;
    if (newItem) {
      // Create Api
      let values = [
        {
          ...data,
          building_code: buildingChange,
          property_code: planningChange,
          multiple: checkBoxState,
        },
      ];
      await CreateNewPlanning({
        body: JSON.stringify({ data: values, user }),
      }).then((res) => {
        // history.push("/maintainence");
        // window.location.reload();
        handleClose && handleClose();
      });
    } else if (modifyItem) {
      // Create Api
      await EditPlanningById(id, {
        body: JSON.stringify({
          ...data,
        }),
      }).then((res) => {
        // window.location.reload();
        handleClose && handleClose();
      });
    }
    setStatus(null);
    setReloadCreateEdit(true);
    handleClose && handleClose();
  };
  const handleSelectArticleItem = (item) => {
    setData({
      ...data,
      article: item.article,
      maintenance_activity: item.maintenance_activity,
      technical_life: item.technical_life,
      u_system: item.u_system,
      unit: item.unit,
      price_per_unit: item.price_per_unit,
      total_cost: ((item.quantity || 0) * item.price_per_unit).toLocaleString(),
    });
  };
  const handleSelectSystemItem = (item) => {
    setData({
      ...data,
      u_system: item.system_code,
    });
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({ ...data, [e.target.name]: e.target.checked });
    } else if (e.target.name === "text") {
      setData({ ...data, [e.target.name]: e.target.value });
    } else {
      setData({ ...data, [e.target.name]: e.target.value.toUpperCase() });
    }
  };
  const defaultProps = {
    handleChange: handleChange,
    required: true,
  };
  const { t } = useTranslation();

  const getMaintenanceSetting = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      let res = await api.get(`/maintenance_settings/${user._id}`);
      setMaintenanceSetting(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculatePlan = () => {
    let currYear = new Date().getFullYear();
    let startYear = parseInt(data?.start_year) || currYear;
    let technical_life = parseInt(data?.technical_life) || 0;
    // console.log("technical_life", technical_life,"startyear", startYear, maintenanceSetting?.plan_duration);
    let startYearDuration =
      startYear + parseInt(maintenanceSetting?.plan_duration) || 0;
    // console.log("startYear_Duration", startYearDuration);
    let count = 0;
    if (technical_life === 0) {
      count = 1;
    } else {
      for (
        let year = startYear;
        year < startYearDuration;
        year += technical_life
      ) {
        count++;
      }
    }
    setPlanCount(count);
  };

  useEffect(() => {
    getMaintenanceSetting();
    // console.log(articleCodeData);
  }, []);
  useEffect(() => {
    handleCalculatePlan();
  }, [data?.start_year, data?.technical_life]);

  const handleCheckRow = (elem) => {
    if (selectedRow?._id == elem?._id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(elem);
    }
  };

  const handleCompChange = (text, p) => {
    if (state == "article_code") {
      setAllArticleCodeData(
        articleCodeData?.filter((el) =>
          el?.article?.toLowerCase()?.includes(text?.toLowerCase())
        )
      );
    } else if (state == "system_code") {
      setAllSystemCodeData(
        systemCodeData?.filter((el) =>
          el?.system_code?.toLowerCase()?.includes(text?.toLowerCase())
        )
      );
    }
  };

  const handleTableSubmit = () => {
    if (!selectedRow) return;
    if (state == "article_code") {
      handleSelectArticleItem(selectedRow);
      setOpen(false);
      setSelectedRow(null);
    } else if (state == "system_code") {
      handleSelectSystemItem(selectedRow);
      setOpen(false);
      setSelectedRow(null);
    }
  };

  const tableBody = (
    <div>
      <div
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        {state == "article_code" ? (
          <>
            <Form.Control
              type="text"
              placeholder={t("common.pages.search")}
              onChange={(e) => handleCompChange(e.target.value)}
              style={{ width: "17rem", marginBottom: "1rem" }}
            />
            <Table bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Article</th>
                  <th>Maintenance Activity</th>
                  <th>System</th>
                  <th>Unit</th>
                  <th>Technical life</th>
                </tr>
              </thead>

              <tbody>
                {allArticleCodeData?.length > 0 &&
                  allArticleCodeData?.map((elem, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            style={{ transform: "scale(1.2)" }}
                            checked={
                              elem?._id == selectedRow?._id ? true : false
                            }
                            onChange={() => handleCheckRow(elem)}
                          />
                        </td>
                        <td>{elem?.article}</td>
                        <td>{elem?.maintenance_activity}</td>
                        <td>{elem?.u_system}</td>
                        <td>{elem?.unit}</td>
                        <td>{elem?.technical_life}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Form.Control
              type="text"
              placeholder={t("common.pages.search")}
              onChange={(e) => handleCompChange(e.target.value)}
              style={{ width: "17rem", marginBottom: "1rem" }}
            />
            <Table bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>System</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {allSystemCodeData?.length > 0 &&
                  allSystemCodeData?.map((elem, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            style={{ transform: "scale(1.2)" }}
                            checked={
                              elem?._id == selectedRow?._id ? true : false
                            }
                            onChange={() => handleCheckRow(elem)}
                          />
                        </td>
                        <td>{elem?.system_code}</td>
                        <td>{elem?.system_name}</td>
                        <td>{elem?.system_code + " " + elem?.system_name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <SidePanel>
        <SidePanelHeader>
          {newItem ? t("planning_page.add_new") : t("planning_page.change")}
          {" " + t("planning_page.maintenance_item")}
        </SidePanelHeader>
        <SidePanelBody>
          <div className="activity-input-container maintenance_item_sidepanel_main">
            <InputBoxDropDown
              {...defaultProps}
              className=""
              mdCol={12}
              defaultValue={newItem ? "" : data?.article}
              value={data?.article}
              text={
                <div style={{ display: "flex", columnGap: "180px" }}>
                  <div>{t("planning_page.article_code")}</div>
                  <span
                    class="material-symbols-outlined text-black"
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "27px",
                    }}
                    onClick={() => {
                      setState("article_code");
                      setTitle(
                        `${
                          t("common.pages.search") +
                          " " +
                          t("planning_page.article_code")
                        }`
                      );
                      setSize("xl");
                      // console.log(articleCodeData);
                      let element = articleCodeData?.find(
                        (comp) => comp.article == data?.article
                      );
                      if (element) {
                        setSelectedRow(element);
                        setAllArticleCodeData(articleCodeData);
                      }
                      setOpen(true);
                    }}
                  >
                    more_horiz
                  </span>
                </div>
              }
              id={"article"}
              handleSubmit={(text) => setArticleCodeChange(text)}
              result={(handleClose) =>
                allArticleCodeData?.map((item) => (
                  <li
                    onClick={() => {
                      handleSelectArticleItem(item);
                      handleClose();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {item.article +
                      " " +
                      item.maintenance_activity.substring(0, 20)}
                    {item.maintenance_activity.length > 20 ? "..." : ""}
                  </li>
                ))
              }
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              required={false}
              defaultValue={newItem ? "" : data?.technical_life}
              value={data?.technical_life}
              text={t("planning_page.technical_life")}
              id={"technical_life"}
            />
            <InputBoxDropDown
              {...defaultProps}
              mdCol={12}
              defaultValue={newItem ? "" : data?.u_system}
              value={data?.u_system}
              text={
                <div style={{ display: "flex", columnGap: "180px" }}>
                  <div>{t("planning_page.system_code")}</div>
                  <span
                    class="material-symbols-outlined text-black"
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "27px",
                    }}
                    onClick={() => {
                      setState("system_code");
                      setTitle(
                        `${
                          t("common.pages.search") +
                          " " +
                          t("planning_page.system_code")
                        }`
                      );
                      setSize("xl");
                      // console.log(systemCodeData);
                      let element = systemCodeData?.find(
                        (comp) => comp.system_code == data?.u_system
                      );
                      if (element) {
                        setSelectedRow(element);
                        setAllSystemCodeData(systemCodeData);
                      }
                      setOpen(true);
                    }}
                  >
                    more_horiz
                  </span>
                </div>
              }
              id={"u_system"}
              handleSubmit={(text) => setSystemCodeChange(text)}
              result={(handleClose) =>
                systemCodeData?.map((item) => (
                  <li
                    onClick={() => {
                      handleSelectSystemItem(item);
                      handleClose();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {item.system_code +
                      " " +
                      item.system_name.split(" ").slice(0, 3).join(" ")}
                  </li>
                ))
              }
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              defaultValue={newItem ? "" : data?.quantity}
              value={data?.quantity}
              text={t("planning_page.quantity")}
              id={"quantity"}
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              defaultValue={newItem ? "" : data?.unit}
              value={data?.unit}
              text={t("planning_page.unit")}
              id={"unit"}
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              defaultValue={newItem ? "" : data?.price_per_unit}
              value={data?.price_per_unit}
              text={t("planning_page.unit_cost")}
              id={"price_per_unit"}
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              defaultValue={
                newItem ? "" : data?.price_per_unit * data.quantity || 0
              }
              value={data?.price_per_unit * data.quantity || 0}
              text={t("planning_page.total_cost")}
              id={"total_cost"}
            />
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              handleFocus={() => {
                if (!data.start_year) {
                  setData((prev) => ({
                    ...prev,
                    start_year: new Date().getFullYear(),
                  }));
                }
              }}
              value={data?.start_year}
              min={"1900"}
              max={"2100"}
              text={t("planning_page.start_year")}
              id={"start_year"}
            />
            <div className="maintenance_plan_checkBox_main">
              <Form.Check
                type="checkbox"
                id="checkboxId"
                checked={checkBoxState}
                onChange={() => setCheckBoxState(!checkBoxState)}
                className="plan_checkbox"
              />
              <span className="maintenance_plan_checkBox_text">
                {" "}
                {t(`planning_page.This will add `)} {planCount}{" "}
                {t(`planning_page.items to the plan`)}
              </span>
            </div>
            <DisabledInputBox
              {...defaultProps}
              mdCol={12}
              type={"number"}
              required={false}
              defaultValue={newItem ? "" : data?.previous_year}
              value={data?.previous_year}
              min={new Date().getFullYear() - 100}
              max={new Date().getFullYear() + 100}
              text={t("planning_page.previous_year")}
              id={"previous_year"}
            />
            <div>
              <Dropdown className="dropdown_year">
                <Dropdown.Toggle className="activites_year_dropdown activtesYear_dropdown_btn activites_dropdown">
                  <div className="status_color_main">
                    {status === "Planerad" ? (
                      <div className="plan_color_div dropdown_icon plan_color"></div>
                    ) : status === "Akut" ? (
                      <div className="plan_color_div dropdown_icon akut_color"></div>
                    ) : status === "Eftersatt" ? (
                      <div className="plan_color_div dropdown_icon efter_color"></div>
                    ) : status === "Beslutad" ? (
                      <div className="plan_color_div dropdown_icon beslu_color"></div>
                    ) : status === "Utford" ? (
                      <div className="plan_color_div dropdown_icon utford_color"></div>
                    ) : (
                      <div className="plan_color_div dropdown_icon plan_color"></div>
                    )}
                    {status ? status : "Planerad"}
                  </div>
                  <FaCaretDown />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setStatus("Planerad")}
                    className="plan_main"
                  >
                    <div className="plan_color_div dropdown_icon plan_color"></div>
                    Planerad
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setStatus("Akut")}
                    className="plan_main"
                  >
                    <div className="plan_color_div dropdown_icon akut_color"></div>
                    Akut
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setStatus("Eftersatt")}
                    className="plan_main"
                  >
                    <div className="plan_color_div dropdown_icon efter_color"></div>
                    Eftersatt
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setStatus("Beslutad")}
                    className="plan_main"
                  >
                    <div className="plan_color_div dropdown_icon beslu_color"></div>
                    Beslutad
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setStatus("Utford")}
                    className="plan_main"
                  >
                    <div className="plan_color_div dropdown_icon utford_color"></div>
                    Utford
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex w-100 justify-content-between inspect_main">
              <CheckBox
                {...defaultProps}
                onChange={handleChange}
                text={t("planning_page.inspect")}
                required={false}
                id={"inspection_flag"}
                value={data?.inspection_flag}
                defaultChecked={newItem ? false : data?.inspection_flag}
              />
              <CheckBox
                {...defaultProps}
                onChange={handleChange}
                text={t("planning_page.risk")}
                required={false}
                id={"risk_flag"}
                value={data?.risk_flag}
                defaultChecked={newItem ? false : data?.risk_flag}
              />
              <CheckBox
                {...defaultProps}
                onChange={handleChange}
                text={t("planning_page.project")}
                required={false}
                id={"project_flag"}
                value={data?.project_flag}
                defaultChecked={newItem ? false : data?.project_flag}
              />
            </div>
            <div className="d-flex invest_main">
              <div style={{ flex: "1 0 40%" }}>
                <CheckBox
                  {...defaultProps}
                  onChange={handleChange}
                  text={t("planning_page.investment")}
                  required={false}
                  id={"invest_flag"}
                  value={data?.invest_flag}
                  defaultChecked={newItem ? false : data?.invest_flag}
                />
              </div>

              <div
                className="d-flex align-items-center justify-content-center mx-2"
                style={{ height: "103px" }}
              >
                <DisabledInputBox
                  {...defaultProps}
                  mdCol={4}
                  required={false}
                  id={"invest_percentage"}
                  value={data?.invest_percentage}
                  defaultChecked={newItem ? "" : data?.invest_percentage}
                  addItem={true}
                />
                <span className="mx-2">{t("planning_page.percent")}</span>
              </div>
            </div>
            <div className="d-flex invest_main">
              <div style={{ flex: "1 0 40%" }}>
                <CheckBox
                  {...defaultProps}
                  text={t("planning_page.energy_savings")}
                  onChange={handleChange}
                  required={false}
                  id={"energy_flag"}
                  value={data?.energy_flag}
                  defaultChecked={newItem ? false : data?.energy_flag}
                />
              </div>

              <div
                className="d-flex align-items-center justify-content-center mx-2"
                style={{ height: "103px" }}
              >
                <DisabledInputBox
                  {...defaultProps}
                  mdCol={4}
                  required={false}
                  id={"energy_save_percentage"}
                  value={data?.energy_save_percentage}
                  defaultChecked={newItem ? "" : data?.energy_save_percentage}
                  addItem={true}
                />
                <span className="mx-2">{t("planning_page.percent")}</span>
              </div>
            </div>
            <div className="inspect_main">
              Text
              <textarea
                {...defaultProps}
                required={false}
                onChange={handleChange}
                defaultValue={newItem ? "" : data?.text}
                value={data?.text}
                className="form-control"
                id="text"
                name="text"
                rows="3"
              ></textarea>
            </div>
          </div>
        </SidePanelBody>
        <SidePanelFooter>
          <Button main type="submit">
            {t("planning_page.submit")}
          </Button>
          <Button
            secondary
            type="button"
            onClick={() => {
              handleClose();
              close();
            }}
          >
            {t("planning_page.cancel")}
          </Button>
        </SidePanelFooter>
      </SidePanel>
      <CustomModal
        theme={"dark"}
        open={open}
        setOpen={setOpen}
        title={title}
        cancelText={t("property_page.cancel")}
        okText={t("property_page.submit")}
        handleOk={
          state == "article_code" ? handleTableSubmit : handleTableSubmit
        }
        body={state == "article_code" ? tableBody : tableBody}
        size={size}
      />
    </form>
  );
};

export default NewItemPlanningModal;
