import React, { useEffect, useState } from "react";
import Loader from "components/common/Loader";
import api from "api";
import {
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import "./Planning.css";
import { Link } from "react-router-dom";
import Filter from "./PlanningFilter";
import { FilterSupervisionPlanning } from "lib/PlanningLib";
import { AiFillCaretDown } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

const Planning = () => {
  const [components, setComponents] = useState([]);
  const [users, setUsers] = useState([]);

  const [clickComponent, setClickComponent] = useState(null);

  const [loading, setLoading] = useState(false);
  // Filter State
  const [filterValues, setFilterValues] = useState({});
  const { t } = useTranslation();

  const getComponentsData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/components`);
      setComponents(data?.filter((el) => el?.component_code !== ""));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUsersData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { data } = await api.get(`/users/adminId/${user?._id}`);
      setUsers(data);
      console.log(users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getComponentsData();
    getUsersData();
  }, []);

  const changeUser = async (value) => {
    try {
      const { data } = await api.patch(
        `/components/update-changeby/${clickComponent}`,
        {
          value,
        }
      );
      setClickComponent(null);
      setComponents(
        components?.map((elem) => {
          if (elem._id == data._id) {
            return (elem = data);
          } else {
            return elem;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowProperty = async (value) => {
    try {
      localStorage.setItem("property", value?.property_code?.property_code);
      localStorage.setItem("building", value?.building_code?.building_code);
      localStorage.setItem("component", value?.component_code);
    } catch (error) {
      console.log(error);
    }
  };

  // Filter Code
  const handleFindClick = async () => {
    // perform find logic using filterValues

    let filterObj = {};
    for (const key in filterValues) {
      if (filterValues[key]?.length > 0) {
        filterObj[key] = filterValues[key];
      }
    }
    const res = await FilterSupervisionPlanning({
      body: JSON.stringify({
        filters: filterObj,
      }),
    });
    const newData = await res.json();
    setComponents(newData);
  };

  const planningColumn = [
    {
      name: t("common.pages.user"),
      cell: (row, index, column, id) => {
        return (
          <p style={{ marginBottom: "0rem" }}>
            {/* {row?.responsible_user && ( */}
            <Dropdown
              className="planning_dropdown"
              onClick={() => setClickComponent(row?._id)}
              // drop="up"
            >
              <Dropdown.Toggle className="dropdown_menu">
                <OverlayTrigger
                  overlay={<Tooltip>{row.responsible_user}</Tooltip>}
                >
                  <Button className="user_email" variant="secondary">
                    <span className="email_wrapper">
                      {row?.responsible_user?.length > 15
                        ? `${row?.responsible_user.substring(0, 15)}...`
                        : !row?.responsible_user
                        ? "Please Select User"
                        : row?.responsible_user}
                    </span>
                  </Button>
                </OverlayTrigger>
                <AiFillCaretDown className="downArrow" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="planning_dropdown_menu">
                {users?.map((elem) => (
                  <Dropdown.Item onClick={() => changeUser(elem?.email)}>
                    {elem?.email}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {/* // )} */}
          </p>
        );
      },
      sortable: true,
      selector: "responsible_user",
      width: "222px",
    },
    {
      name: t("common.pages.building"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row?.building_code?.building_code}
          </p>
        );
      },
      selector: "building_code",
      sortable: true,
      width: "93px",
    },
    {
      name: "ID",
      cell: (row, index, column, id) => {
        return (
          <Link
            to={"/property"}
            onClick={() => handleShowProperty(row)}
            className="planning_component_code"
          >
            {row.component_code}
          </Link>
        );
      },
      width: "140px",
      sortable: true,
      selector: "component_code",
    },
    {
      name: t("common.pages.name"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code planning_name">
            {row.long_name}
          </p>
        );
      },
      selector: "name",
      width: "330px",
      sortable: true,
    },
    {
      name: "System",
      cell: (row, index, column, id) => {
        return <p className="planning_building_code">{row.u_system}</p>;
      },
      sortable: true,
      selector: "u_system",
      width: "123px",
    },
    {
      name: t("property_page.location"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">{row.position_of_code} </p>
        );
      },
      selector: "position_of_code",
      width: "199px",
      sortable: true,
    },
    {
      name: t("property_page.Act._T"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.attendance_interval_unit &&
              row.attendance_interval_value &&
              row.attendance_lastest_date &&
              row.attendance_next_date &&
              "Tillsyn"}
          </p>
        );
      },

      width: "80px",
    },
    {
      name: "IV",
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.attendance_interval_value}{" "}
          </p>
        );
      },
      sortable: true,
      selector: "attendance_interval_value",
      width: "70px",
    },

    {
      name: "IU",
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.attendance_interval_unit}
          </p>
        );
      },
      sortable: true,
      selector: "attendance_interval_unit",
      width: "70px",
    },

    {
      name: t("common.pages.previous"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.attendance_lastest_date}{" "}
          </p>
        );
      },
      sortable: true,
      selector: "attendance_lastest_date",
      width: "125px",
    },
    {
      name: t("common.pages.next"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">{row.attendance_next_date} </p>
        );
      },
      sortable: true,
      selector: "attendance_next_date",
      width: "125px",
    },
    {
      name: t("property_page.Act._S"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.maintenance_interval_unit &&
            row.maintenance_interval_value &&
            row.maintenance_lastest_date &&
            row.maintenance_next_date
              ? "Skötsel"
              : null}{" "}
          </p>
        );
      },
      sortable: true,
      width: "90px",
    },
    {
      name: "IV",
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.maintenance_interval_value}{" "}
          </p>
        );
      },
      sortable: true,
      selector: "maintenance_interval_value",
      width: "60px",
    },
    {
      name: "IU",
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.maintenance_interval_unit}{" "}
          </p>
        );
      },
      sortable: true,
      selector: "maintenance_interval_unit",
      width: "65px",
    },

    {
      name: t("common.pages.previous"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">
            {row.maintenance_lastest_date}{" "}
          </p>
        );
      },
      selector: "maintenance_lastest_date",
      sortable: true,
      width: "125px",
    },
    {
      name: t("common.pages.next"),
      cell: (row, index, column, id) => {
        return (
          <p className="planning_building_code">{row.maintenance_next_date} </p>
        );
      },
      sortable: true,
      selector: "maintenance_next_date",
      width: "125px",
    },
  ];

  return loading ? (
    <>
      {" "}
      <Loader />{" "}
    </>
  ) : (
    <>
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <div style={{ width: "99%", overflowX: "scroll", marginRight: "1rem" }}>
        <DataTable
          data={components}
          columns={planningColumn}
          noDataComponent={t("common.pages.There are no records to display")}
          highlightOnHover
          responsive
          pagination
          className="pagination_planing_table"
          paginationComponentOptions={{
            rowsPerPageText: t("planning_page.rows_per_page"),
          }}
        />
      </div>
    </>
  );
};

export default Planning;
