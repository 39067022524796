import React, { useState, useEffect } from "react";
import MaintainancePage from "../components/PlanningPage/MaintainancePage";
import Tabs from "../components/common/Tabs";
import { usePlanningContextCheck } from "../context/SidebarContext/PlanningContextCheck";
import Breadcrumbs from "../components/common/Breadcrumbs";
import Dropdown from "../components/common/Dropdown";
import DeleteModal from "components/PlanningPage/MaintainancePage/components/Report/ActivitesYear/DeleteModal";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const defaultState = () => <p>{t("planning_page.select_property")} </p>;
const defaultStateBuilding = () => <p>Please select a Building</p>;

const Maintaince = () => {
  const [currentAction, setCurrentAction] = useState();
  const [ActiveComponent, setActiveComponent] = useState({
    Component: defaultState,
    props: {},
  });
  const [currentTab, setCurrentTab] = useState();
  // Delete Modal State
  const [show, setShow] = useState(false);

  const {
    planningChange,
    buildingChange,
    setBuildingChange,
    setActiveTabMaintenance,
    setPlanningChange,
  } = usePlanningContextCheck();

  const { t } = useTranslation();
  const newItem = currentAction === "add_item" ? true : false;
  const deleteItem = currentAction === "delete" ? true : false;
  const newPackage = currentAction === "add_package" ? true : false;
  const modifyItem = currentAction === "modify" ? true : false;
  const printItem = currentAction === "print" ? true : false;
  const createReport = currentAction === "create_report" ? true : false;
  const printAnalysis = currentAction === "printAnalysis" ? true : false;

  const handleChangeAction = (item) => {
    const buildingCode = localStorage.getItem("planning_building_code");
    if (!buildingCode && item === "add_package") {
      setShow(true);
    } else {
      setCurrentAction(item);
    }
  };

  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name);
  };

  useEffect(() => {
    const tabValue = getQueryParam("tab");
    if (tabValue === "addNewMaintenance") {
      // setTimeout(() => {
      handleChangeAction("add_item");
      // }, 1000);
    }
  }, []);

  useEffect(() => {
    //debugger;
    if (buildingChange || planningChange || !planningChange) {
      setActiveComponent({
        Component: MaintainancePage,
        props: {
          handleChangeAction,
          newItem,
          deleteItem,
          newPackage,
          modifyItem,
          printItem,
          createReport,
          printAnalysis,
          currentTab,
        },
      });
    } else if (planningChange) {
      setActiveComponent({
        Component: defaultStateBuilding,
        props: {
          handleChangeAction,
          newItem,
        },
      });
    }
  }, [buildingChange, currentAction, newItem, planningChange]);

  const maintainceTabValues = [
    {
      name: t("planning_page.create_edit_plan"),
      id: "create_edit_plan",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        createEditPlan: true,
      },
    },
    {
      name: t("planning_page.overview"),
      id: "overview",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        overview: true,
        selectCreateEditPlan: () => setCurrentTab("create_edit_plan"),
      },
    },
    {
      name: t("planning_page.batch_editing"),
      id: "batch_editing",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        batchediting: true,
      },
    },
    {
      name: t("planning_page.analysis"),
      id: "analysis",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        analysis: true,
      },
    },
    {
      name: t("planning_page.report"),
      id: "report",
      Component: ActiveComponent.Component,
      props: {
        ...ActiveComponent.props,
        report: true,
      },
    },
  ];

  const dropDownItems = [
    {
      if: currentTab === "create_edit_plan" && buildingChange,
      text: t("common.pages.add_item"),
      id: "add_item",
      icon: "add",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if: currentTab === "create_edit_plan" && buildingChange,
      text: t("common.pages.add_package"),
      id: "add_package",
      icon: "add",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if:
        (currentTab === "create_edit_plan" ||
          currentTab === "batch_editing" ||
          currentTab === "overview") &&
        planningChange,

      text: t("planning_page.delete_selected"),
      id: "delete",
      icon: "remove",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if: currentTab === "create_edit_plan",

      text: t("common.pages.modify_item"),
      id: "modify",
      icon: "edit",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if: currentTab === "report",
      text: t("planning_page.print"),
      id: "print",
      icon: "export_notes",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if: currentTab === "report",
      text: t("planning_page.create_report"),
      id: "create_report",
      icon: "export_notes",
      handleClick: (value) => handleChangeAction(value),
    },
    {
      if: currentTab === "analysis",
      text: t("planning_page.print"),
      id: "printAnalysis",
      icon: "export_notes",
      handleClick: (value) => handleChangeAction(value),
    },
  ];
  const breadCrumbItems = [
    {
      if: true,
      value: planningChange || t("property_page.Select_Building"),
      handleClick: () => {
        setBuildingChange(null);
      },
    },
    {
      if: buildingChange,
      value: buildingChange,
      handleClick: () => {},
    },
  ];
  useEffect(() => {
    const activeTabId = localStorage.getItem("activeTabIdPlanningMaintainance");
    if (activeTabId) {
      setCurrentTab(activeTabId);
    } else {
      setCurrentTab(maintainceTabValues[0].id);
    }
  }, []);

  useEffect(() => {
    if (currentTab) {
      localStorage.setItem("activeTabIdPlanningMaintainance", currentTab);
      setActiveTabMaintenance(currentTab);
    }
  }, [currentTab]);

  // Delete Modal Function
  const deleteModalClose = () => {
    setShow(false);
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          {currentTab === undefined || currentTab === "create_edit_plan" ? (
            <Breadcrumbs items={breadCrumbItems} />
          ) : (
            <div></div>
          )}
          <Dropdown
            handleClick={handleChangeAction}
            nameReset={currentAction ? false : true}
            name={t("common.pages.actions")}
            items={dropDownItems}
          />
        </div>
        <div className="my-4">
          {currentTab && (
            <Tabs
              tabValues={maintainceTabValues}
              activeTabId={currentTab}
              onTabChange={(item) => {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.delete("plan_id");
                urlParams.delete("prop_id");
                urlParams.delete("build_code");
                const newUrl = `${
                  window.location.pathname
                }?${urlParams.toString()}`;
                window.history.pushState({}, "", newUrl);
                setCurrentTab(item);
              }}
              maintenanceTabWidth={true}
              settingsIcon={true}
            />
          )}
        </div>
      </div>
      {/* // Delete Modal */}
      {show && (
        <DeleteModal
          show={show}
          maintenanceCreate={true}
          deleteModalClose={deleteModalClose}
        />
      )}
    </>
  );
};

export default Maintaince;
